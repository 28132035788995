import React, { useState, useEffect, useContext } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import FilterSidebar from "../inc/FilterSidebar";
import { Col, Container, Row, Table, Form, Button } from "react-bootstrap";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import { add_Price, price_Edit, price_Fetch } from "../services/ApiCalls";
import { ToastContainer, toast } from "react-toastify";
import LoadingContext from "../services/LoadingContext";
import { setNumberValidation } from "../services";
import { AppStoreContext } from "../../store/AppStore";

const Pricing = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { userDetail } = useContext(AppStoreContext);
  const [toggle, setToggle] = useState(false);
  const [getFetchPrice, setGetFetchPrice] = useState({});
  // distance
  const [distance250, setDistance250] = useState("");
  const [distance500, setDistance500] = useState("");
  const [distance700, setDistance700] = useState("");
  const [distance950, setDistance950] = useState("");
  const [distance1200, setDistance1200] = useState("");
  const [distanceGrater1200, setDistanceGrater1200] = useState("");
  // weight
  const [weight100, setWeight100] = useState("");
  const [weight101, setWeight101] = useState("");
  const [weight501, setWeight501] = useState("");
  const [weight1001, setWeight1001] = useState("");
  const [weight3501, setWeight3501] = useState("");
  const [weight5001, setWeight5001] = useState("");
  const [weight10001, setWeight10001] = useState("");
  const [weight15001, setWeight15001] = useState("");
  const [weight20000, setWeight20000] = useState("");
  // vehicle
  const [generalCargo, setGeneralCargo] = useState("");
  const [tanker, setTanker] = useState("");
  const [refrigerated, setRefrigerated] = useState("");
  // Prices
  const [carrierPrice, setCarrierPrice] = useState("");
  const [clientAccountPrice, setClientAccountPrice] = useState("");
  const [userAccountFinalPrice, setUserAccountFinalPrice] = useState("");

  //client 
  const [client1, setClient1] = useState("");
  const [client51, setClient51] = useState("");
  const [client101, setClient101] = useState("");
  const [client201, setClient201] = useState("");

  const userDeatildata = userDetail;
  const { _id } = userDeatildata;

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [carrierPriceError, setCarrierPriceError] = useState(0);
  const [clientAccountPriceError, setClientAccountPriceError] = useState(0);
  const [userAccountFinalPriceError, setUserAccountFinalPriceError] =
    useState(0);

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    fetchPrice();
  }, []);

  // Fetch Price Api
  const fetchPrice = () => {
    const payload = {
      adminId: _id,
    };
    setIsLoading(true);
    price_Fetch(payload)
      .then((res) => {
        setGetFetchPrice(res.result.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (getFetchPrice) {
      // distance
      setDistance250(
        getFetchPrice.Distance_based_margin &&
          getFetchPrice.Distance_based_margin[0].Margin
      );
      setDistance500(
        getFetchPrice.Distance_based_margin &&
          getFetchPrice.Distance_based_margin[1].Margin
      );
      setDistance700(
        getFetchPrice.Distance_based_margin &&
          getFetchPrice.Distance_based_margin[2].Margin
      );
      setDistance950(
        getFetchPrice.Distance_based_margin &&
          getFetchPrice.Distance_based_margin[3].Margin
      );
      setDistance1200(
        getFetchPrice.Distance_based_margin &&
          getFetchPrice.Distance_based_margin[4].Margin
      );
      setDistanceGrater1200(
        getFetchPrice.Distance_based_margin &&
          getFetchPrice.Distance_based_margin[5].Margin
      );
      // // weight
      setWeight100(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[0].margin
      );
      setWeight101(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[1].margin
      );
      setWeight501(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[2].margin
      );
      setWeight1001(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[3].margin
      );
      setWeight3501(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[4].margin
      );
      setWeight5001(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[5].margin
      );
      setWeight10001(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[6].margin
      );
      setWeight15001(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[7].margin
      );
      setWeight20000(
        getFetchPrice.Weight_based_margin &&
          getFetchPrice.Weight_based_margin[8].margin
      );
      // vehicle
      setGeneralCargo(
        getFetchPrice.Vehicle_based_margin &&
          getFetchPrice.Vehicle_based_margin[0].margin
      );
      setTanker(
        getFetchPrice.Vehicle_based_margin &&
          getFetchPrice.Vehicle_based_margin[1].margin
      );
      setRefrigerated(
        getFetchPrice.Vehicle_based_margin &&
          getFetchPrice.Vehicle_based_margin[2].margin
      );

      setCarrierPrice(
        getFetchPrice.Carriers_price && getFetchPrice.Carriers_price
      );
      setClientAccountPrice(
        getFetchPrice.client_account_prize && getFetchPrice.client_account_prize
      );
      setUserAccountFinalPrice(
        getFetchPrice.user_account_final_prize &&
          getFetchPrice.user_account_final_prize
      );

      //client

      setClient1(
        getFetchPrice.Client_based_margin &&
          getFetchPrice.Client_based_margin[0].fees
      );
      setClient51(
        getFetchPrice.Client_based_margin &&
          getFetchPrice.Client_based_margin[1].fees
      );
      setClient101(
        getFetchPrice.Client_based_margin &&
          getFetchPrice.Client_based_margin[2].fees
      );
      setClient201(
        getFetchPrice.Client_based_margin &&
          getFetchPrice.Client_based_margin[3].fees
      );
    }
  }, [getFetchPrice]);

  // Add Price Api

  // const addPriceDetial = () => {
  //   const payload = {
  //     adminId: _id,
  //     Carriers_price: carrierPrice,
  //     client_account_prize: clientAccountPrice,
  //     user_account_final_prize: userAccountFinalPrice,
  //     Distance_based_margin: [
  //       {
  //         DISTANCE: "< 250km",
  //         Margin: distance250,
  //       },
  //       {
  //         DISTANCE: "251 - 500km",
  //         Margin: distance500,
  //       },
  //       {
  //         DISTANCE: "501 - 700km",
  //         Margin: distance700,
  //       },
  //       {
  //         DISTANCE: "701 - 950km",
  //         Margin: distance950,
  //       },
  //       {
  //         DISTANCE: "951 - 1200km",
  //         Margin: distance1200,
  //       },
  //       {
  //         DISTANCE: "> 1200km",
  //         Margin: distanceGrater1200,
  //       },
  //     ],
  //     Weight_based_margin: [
  //       {
  //         WEIGHT: "< 100",
  //         margin: weight100,
  //       },
  //       {
  //         WEIGHT: "101 - 500",
  //         margin: weight101,
  //       },
  //       {
  //         WEIGHT: "501 - 1000",
  //         margin: weight501,
  //       },
  //       {
  //         WEIGHT: "1001 - 3500",
  //         margin: weight1001,
  //       },
  //       {
  //         WEIGHT: "3501 - 5000",
  //         margin: weight3501,
  //       },
  //       {
  //         WEIGHT: "5001 - 10000",
  //         margin: weight5001,
  //       },
  //       {
  //         WEIGHT: "10001 - 15000",
  //         margin: weight10001,
  //       },
  //       {
  //         WEIGHT: "15001 - 20000",
  //         margin: weight15001,
  //       },
  //       {
  //         WEIGHT: "> 20000",
  //         margin: weight20000,
  //       },
  //     ],
  //     Vehicle_based_margin: [
  //       {
  //         vehicle: "General cargo",
  //         margin: generalCargo,
  //       },
  //       {
  //         vehicle: "Tanker",
  //         margin: tanker,
  //       },
  //       {
  //         vehicle: "Refrigerated",
  //         margin: refrigerated,
  //       },
  //     ],
  //   };
  //   add_Price(payload)
  //     .then((res) => {
  //       console.log("price res", res);
  //       if (res.status === true) {
  //         toast(res.msg);
  //       } else {
  //         toast(res.msg);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  // Edit Price Api

  const editPriceDetial = () => {
    const payload = {
      adminId: _id,
      Carriers_price: carrierPrice,
      client_account_prize: clientAccountPrice,
      user_account_final_prize: userAccountFinalPrice,
      Distance_based_margin: [
        {
          DISTANCE: "< 250km",
          Margin: distance250,
        },
        {
          DISTANCE: "251 - 500km",
          Margin: distance500,
        },
        {
          DISTANCE: "501 - 700km",
          Margin: distance700,
        },
        {
          DISTANCE: "701 - 950km",
          Margin: distance950,
        },
        {
          DISTANCE: "951 - 1200km",
          Margin: distance1200,
        },
        {
          DISTANCE: "> 1200km",
          Margin: distanceGrater1200,
        },
      ],
      Weight_based_margin: [
        {
          WEIGHT: "< 100",
          margin: weight100,
        },
        {
          WEIGHT: "101 - 500",
          margin: weight101,
        },
        {
          WEIGHT: "501 - 1000",
          margin: weight501,
        },
        {
          WEIGHT: "1001 - 3500",
          margin: weight1001,
        },
        {
          WEIGHT: "3501 - 5000",
          margin: weight3501,
        },
        {
          WEIGHT: "5001 - 10000",
          margin: weight5001,
        },
        {
          WEIGHT: "10001 - 15000",
          margin: weight10001,
        },
        {
          WEIGHT: "15001 - 20000",
          margin: weight15001,
        },
        {
          WEIGHT: "> 20000",
          margin: weight20000,
        },
      ],
      Vehicle_based_margin: [
        {
          vehicle: "General cargo",
          margin: generalCargo,
        },
        {
          vehicle: "Tanker",
          margin: tanker,
        },
        {
          vehicle: "Refrigerated",
          margin: refrigerated,
        },
      ],
      Client_based_margin: [
        {
          number_of_order: "1 - 50",
          fees: client1,
        },
        {
          number_of_order: "51 - 100",
          fees: client51,
        },
        {
          number_of_order: "101 - 200",
          fees: client101,
        },
        {
          number_of_order: ">= 201",
          fees: client201,
        },
      ],
    };

    setIsLoading(true);
    price_Edit(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Price Update Successfully");
        } else {
          toast(res.msg);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!carrierPrice) {
      errorCount++;
      let msg = "Please enter Carrier’s price.";
      setCarrierPriceError(msg);
    } else {
      setCarrierPriceError(null);
    }

    if (!clientAccountPrice) {
      errorCount++;
      let msg = "Please enter Client account price.";
      setClientAccountPriceError(msg);
    } else {
      setClientAccountPriceError(null);
    }

    if (!userAccountFinalPrice) {
      errorCount++;
      let msg = "Please enter User account final price.";
      setUserAccountFinalPriceError(msg);
    } else {
      setUserAccountFinalPriceError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const onSubmit = () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("errorCount", errorCount);
      return;
    } else {
      editPriceDetial();
      console.log("call api");
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [carrierPrice, clientAccountPrice, userAccountFinalPrice]);

  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <NavBar />
          <FilterSidebar close={handleToggle} />

          <Container fluid className="pt-100">
            <Row>
              <Col lg={12}>
                <div className="main_div">
                  <Row className="cardChat_margin cardChat_marginPrice">
                    <Col lg={12}>
                      <div className="cardChart">
                        <h2>Distance-based margin</h2>
                        <Table responsive className="table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: 120, minWidth: 120 }}>
                                DISTANCE (KM)
                              </th>
                              <th>{"<"} 250</th>
                              <th>251 - 500</th>
                              <th>501 - 700</th>
                              <th>701 - 950</th>
                              <th>951 - 1 200</th>
                              <th>{">"} 1 200</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="fmed">MARGIN (%)</span>
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={distance250}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setDistance250
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={distance500}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setDistance500
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={distance700}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setDistance700
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={distance950}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setDistance950
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={distance1200}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setDistance1200
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={distanceGrater1200}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setDistanceGrater1200
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                  <Row className="cardChat_margin cardChat_marginPrice mt-3">
                    <Col lg={12}>
                      <div className="cardChart">
                        <h2>Weight-based margin</h2>
                        <Table responsive className="table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: 120, minWidth: 120 }}>
                                WEIGHT (KG)
                              </th>
                              <th>{"<"} 100</th>
                              <th>101 - 500</th>
                              <th>501 - 1000</th>
                              <th>1001 - 3500</th>
                              <th>3 501 - 5 000</th>
                              <th>5 001 - 10 000</th>
                              <th>10 001 - 15 000</th>
                              <th>15 001 - 20 000</th>
                              <th>{">"} 20 000</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="fmed">MARGIN (%)</span>
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight100}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight100
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight101}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight101
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight501}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight501
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight1001}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight1001
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight3501}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight3501
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight5001}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight5001
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight10001}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight10001
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight15001}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight15001
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={weight20000}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setWeight20000
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                  <Row className="cardChat_margin cardChat_marginPrice mt-3">
                    <Col lg={12}>
                      <div className="cardChart">
                        <h2>Vehicle-based margin</h2>
                        <Table responsive className="table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: 120, minWidth: 120 }}>
                                VEHICLE (V)
                              </th>
                              <th>General cargo</th>
                              <th>Tanker</th>
                              <th>Refrigerated</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="fmed">MARGIN (%)</span>
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={generalCargo}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setGeneralCargo
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={tanker}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setTanker
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={refrigerated}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setRefrigerated
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                  <Row className="cardChat_margin cardChat_marginPrice mt-3">
                    <Col lg={12}>
                      <div className="cardChart">
                        <h2>Prices</h2>
                        <Row>
                          <Col lg={3}>
                            <TextInputWithLabel
                              label="Carrier’s price"
                              placeholder="Enter a price"
                              reqstart="*"
                              type="text"
                              value={carrierPrice}
                              onChange={(text) => {
                                setNumberValidation(
                                  text.target.value,
                                  setCarrierPrice
                                );
                              }}
                            />
                            {carrierPriceError && Submit ? (
                              <div
                                style={{
                                  fontFamily: "AvenirLTProLight",
                                  color: "#FF0000",
                                  opacity: 0.6,
                                  marginTop: -13,
                                }}
                              >
                                {carrierPriceError}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={3}>
                            <TextInputWithLabel
                              label="Client account price"
                              placeholder="Enter a price"
                              reqstart="*"
                              type="text"
                              value={clientAccountPrice}
                              onChange={(text) => {
                                setNumberValidation(
                                  text.target.value,
                                  setClientAccountPrice
                                );
                              }}
                            />
                            {clientAccountPriceError && Submit ? (
                              <div
                                style={{
                                  fontFamily: "AvenirLTProLight",
                                  color: "#FF0000",
                                  opacity: 0.6,
                                  marginTop: -13,
                                }}
                              >
                                {clientAccountPriceError}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <TextInputWithLabel
                              label="User account final price"
                              placeholder="Enter a formula"
                              reqstart="*"
                              type="text"
                              value={userAccountFinalPrice}
                              onChange={(text) => {
                                setNumberValidation(
                                  text.target.value,
                                  setUserAccountFinalPrice
                                );
                              }}
                            />
                            {userAccountFinalPriceError && Submit ? (
                              <div
                                style={{
                                  fontFamily: "AvenirLTProLight",
                                  color: "#FF0000",
                                  opacity: 0.6,
                                  marginTop: -13,
                                }}
                              >
                                {userAccountFinalPriceError}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row className="cardChat_margin cardChat_marginPrice mt-3">
                    <Col lg={12}>
                      <div className="cardChart">
                        <h2>Client-based margin</h2>
                        <Table responsive className="table-bordered">
                          <thead>
                            <tr>
                              <th style={{ width: 120, minWidth: 120 }}>
                                Number of orders
                              </th>
                              <th>1 - 50</th>
                              <th>51 - 100</th>
                              <th>101 - 200</th>
                              <th>{">="} 201</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="fmed">Fee of each order (€)</span>
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={client1}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setClient1
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={client51}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setClient51
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={client101}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setClient101
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  value={client201}
                                  onChange={(text) => {
                                    setNumberValidation(
                                      text.target.value,
                                      setClient201
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>

                </div>
              </Col>
            </Row>

            <div className="text-center" style={{ marginBottom: 10 }}>
              <Button
                onClick={onSubmit}
                variant="primary"
                className="login_btn mt-2"
              >
                Save
              </Button>
            </div>
            <ToastContainer />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
