import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "./index.scss";
import { QuotesDataProvider } from "./component/services/QuotesData";
import { AppStoreDataProvider } from "./store/AppStore";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode >  COMMENTED OF MAP MARKERS
  <AppStoreDataProvider>
    <QuotesDataProvider>
      <App />
    </QuotesDataProvider>
  </AppStoreDataProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
