import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Dashboard from "./component/pages/Dashboard";
import Account from "./component/pages/Account";
import Team from "./component/pages/Team";
import Carriers from "./component/pages/Carriers";
import Client from "./component/pages/Client";
import Users from "./component/pages/Users";
import Pricing from "./component/pages/Pricing";
import Settings from "./component/pages/Settings";
import Login from "./component/pages/Login";
import ForgotPassword from "./component/pages/ForgotPassword";
import ResetPassword from "./component/pages/ResetPassword";
import StaffLogin from "./component/pages/StaffLogin";
import Quotes from "./component/pages/Quotes";
import Orders from "./component/pages/Orders";
import Tracking from "./component/pages/Tracking";
import OrdersArchives from "./component/pages/OrdersArchives";
import LoadingContext from "./component/services/LoadingContext";
import LoaderComponent from "./component/customcomponents/LoaderComponent";
import PageNotFound from "./component/pages/PageNotFound";
import ClientLogin from "./component/pages/ClientLoign";
import Availabilities from "./component/pages/Availabilities";
import Home from "./component/pages/Home";
// WEB IMPORTS
import Header from "./web_components/inc/Header";
import Footer from "./web_components/inc/Footer";
import WebHome from "./web_components/pages/Home";
// import Footer from "./component/inc/Footer"
import Solutions from "./web_components/pages/Solutions";
import WebContact from "./web_components/pages/Contact";
import WebLogin from "./web_components/pages/Login";
import WebUserSignup from "./web_components/pages/UserSignup";
function ScrollToTopMain() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// basename={process.env.REACT_APP_BASENAME}
function App() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Router basename={"/"}>
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        {isLoading && <LoaderComponent />}
        <ScrollToTopMain />
        <Header />
        <Routes>
          {/* WEB */}
          <Route exact path="/" element={<WebHome />} />
          <Route path="/user_login" element={<WebLogin />} />
          <Route
            path="/carriersignup"
            element={<WebUserSignup user_type={"Carrier"} />}
          />
          <Route
            path="/usersignup"
            element={<WebUserSignup user_type={"User"} />}
          />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/contact" element={<WebContact />} />
          <Route
            path="/web_tracking"
            element={<Tracking isUserLogin={false} />}
          />
          <Route
            path={`/forget_password`}
            element={<ForgotPassword user_role={"Other"} />}
          />

          {/* ADMIN  */}
          <Route path={`/login`} element={<Login />} />
          <Route
            path={`/admin_forgotPassword`}
            element={<ForgotPassword user_role={"Admin"} />}
          />
          <Route
            path={`/staff_forgotPassword`}
            element={<ForgotPassword user_role={"Staff"} />}
          />

          <Route path={`/resetPassword`} element={<ResetPassword />} />
          <Route path={`/dashboard`} element={<Dashboard />} />
          <Route path={`/account`} element={<Account />} />
          <Route path={`/team`} element={<Team />} />
          <Route path={`/carriers`} element={<Carriers />} />
          <Route path={`/clients`} element={<Client />} />
          <Route path={`/users`} element={<Users />} />
          <Route path={`/pricing`} element={<Pricing />} />
          <Route path={`/settings`} element={<Settings />} />

          {/* staff route */}
          <Route path={`/staffLogin`} element={<StaffLogin />} />

          <Route path={`/quotes`} element={<Quotes />} />

          <Route path={`/orders`} element={<Orders />} />
          <Route path={`/archives`} element={<OrdersArchives />} />
          <Route path={`/tracking`} element={<Tracking isUserLogin={true} />} />

          {/* user route */}
          {/* <Route path={`/userLogin`} element={<UserLogin />} />
          <Route path={`/userSignup`} element={<UserSignup />} />
          <Route path="/contact" element={<Contact />} /> */}

          {/* carrier route */}
          {/* <Route path={`/carrierLogin`} element={<CarrierLogin />} />
          
           */}
          <Route path={`/carrier_quotes`} element={<Home />} />
          <Route path={`/carrier_home`} element={<Home />} />
          <Route path={`/availabilities`} element={<Availabilities />} />
          {/* client route */}
          <Route path={`/clientLogin`} element={<ClientLogin />} />
          <Route path={`*`} element={<PageNotFound />} />
        </Routes>
        <Footer />
      </LoadingContext.Provider>
    </Router>
  );
}

export default App;
