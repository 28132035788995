import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Form, Image } from "react-bootstrap";
import SearchIcon from "../images/searchicon.svg";
import NotificationIcon from "../images/notification.svg";
import { FiMenu } from "react-icons/fi";
import { AppStoreContext } from "../../store/AppStore";

const NavBar = () => {
  const { toggleBodyClass } = useContext(AppStoreContext);

  return (
    <div className="topheader">
      <div className="container-fluid">
        <div className="topheader_flex">
          <Button
            variant="primary"
            className="menuBtn"
            onClick={toggleBodyClass}
          >
            <FiMenu />
          </Button>
          {/* <div className="inPutBox">
            <Form.Control placeholder="Search" />
            <Image src={SearchIcon} alt="" />
          </div> */}
          <Link className="notiIcon">
            <Image src={NotificationIcon} alt="" />
            <Badge bg="danger">5</Badge>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
