import { useState, useEffect, useContext } from "react";
import SideBar from "../inc/SideBar";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  Container,
  Badge,
  Form,
  Pagination,
} from "react-bootstrap";
import { FiPlusCircle, FiMoreVertical } from "react-icons/fi";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import BlockIcon from "../images/blockicon.svg";
import SendEmailIcon from "../images/sendemail.svg";
import UserInfoIcon from "../images/userinfo.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import FilterSidebar from "../inc/FilterSidebar";
import { GoDotFill } from "react-icons/go";
import {
  addStaff,
  country_PhoneNumberCode,
  staff_Block,
  staff_Delete,
  staff_EditDetail,
  staff_list,
  staff_sendEmail,
} from "../services/ApiCalls";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import EditFieldTextInput from "../customcomponents/EditFieldTextInput";
import LoadingContext from "../services/LoadingContext";
import { URL_LINK } from "../services/env";
import { Link } from "react-router-dom";
import FilterIcon from "../images/filter.svg";
import { FiMenu } from "react-icons/fi";
import SearchIcon from "../images/searchicon.svg";
import NotificationIcon from "../images/notification.svg";
import DataTable from "datatables.net-dt";
import ReactPaginate from "react-js-pagination";
import { AppStoreContext } from "../../store/AppStore";

const Team = () => {
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [toggle, setToggle] = useState(false);

  const { setIsLoading } = useContext(LoadingContext);
  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const { _id, email } = userDeatildata;
  const [staffList, setStaffList] = useState([]);
  const [editDetail, setEditDetail] = useState({});

  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedStaffList = staffList.slice(startIndex, endIndex);

  const calculateCount = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = () => {
    const filteredResults = staffList.filter(
      (result) =>
        result.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.accountNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        result.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setStaffList(filteredResults);
  };

  useEffect(() => {
    if (searchQuery === "") {
      fetchStaffList();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    fetchStaffList();
  }, [editModalShow, modalShow]);

  const fetchStaffList = () => {
    const payload = {
      adminId: _id,
    };
    setIsLoading(true);
    staff_list(payload)
      .then((res) => {
        setStaffList(res.result.StaffList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // send mail
  const handleSendEmailClick = (ownerName, staffEmail, id) => {
    const emailAddress = staffEmail;
    const subject = "";
    const body = "";
    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  // send mail api

  // const handleSendEmailClick = (ownerName, staffEmail, id) => {
  //   const payload = {
  //     id: id,
  //     contactEmail: staffEmail,
  //     accountOwnerFirstName: ownerName,
  //     Link: `${URL_LINK}staffLogin`,
  //   };

  //   console.log("send mail Payload", payload);
  //   setIsLoading(true);
  //   staff_sendEmail(payload)
  //     .then((res) => {
  //       console.log("res", res);
  //       if (res.status === true) {
  //         toast("Email sent successfully.");
  //       } else {
  //         toast(res.msg);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       setIsLoading(false);
  //     });
  // };

  // edit
  const handleEditClick = (item) => {
    setEditDetail(item);
    setEditModalShow(true);
  };
  // Block API
  const handleBlockClick = (id, check) => {
    const payload = {
      type: check === "Active" ? "Inactive" : "Active",
      addid: id,
    };
    setIsLoading(true);
    staff_Block(payload)
      .then((res) => {
        if (res.status === true) {
          check === "Active"
            ? toast("Block Successfully")
            : toast("Unblock Successfully");
          fetchStaffList();
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  // Delete API
  const handleDeleteClick = (id) => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      const payload = {
        deleteStatus: "1",
        addid: id,
      };
      setIsLoading(true);
      staff_Delete(payload)
        .then((res) => {
          if (res.status === true) {
            toast("Delete Successfully");
            fetchStaffList();
          } else toast(res.msg);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    } else {
      console.log("cancel team");
    }
  };

  const [isBodyClassActive, setIsBodyClassActive] = useState(false);

  useEffect(() => {
    if (isBodyClassActive) {
      document.body.classList.add("sb-sidenav-toggled");
    } else {
      document.body.classList.remove("sb-sidenav-toggled");
    }
  }, [isBodyClassActive]);

  const toggleBodyClass = () => {
    setIsBodyClassActive(!isBodyClassActive);
  };

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          {/* <NavBar  /> */}
          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => setSearchQuery(text.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>

          <FilterSidebar close={handleToggle} />

          <Container fluid className=" pt-100">
            <div className="quotes_div">
              <Row className="align-items-center mb-3">
                <Col lg={6} xs={6}>
                  <Button
                    onClick={() => setModalShow(true)}
                    variant="primary"
                    className="btn-primaryAll btnPrimary"
                  >
                    <FiPlusCircle /> Add staff
                  </Button>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col sm={12}>
                  <div className="react_table">
                    <Table responsive id="myTable">
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: 110, minWidth: 110 }}>
                            LAST NAME
                          </th>
                          <th style={{ width: 110, minWidth: 110 }}>
                            FIRST NAME
                          </th>
                          <th>STATUS</th>
                          <th>ACCOUNT</th>
                          <th style={{ width: 190, minWidth: 190 }}>
                            POSITION
                          </th>
                          <th>LEVEL</th>
                          <th>EMAIL</th>
                          <th>CREATION</th>
                          <th></th>
                        </tr>
                      </thead>

                      {staffList.length === 0 && (
                        <tr>
                          <td colSpan={9} className="text-center  pt-3 ">
                            No data found
                          </td>
                        </tr>
                      )}

                      <tbody>
                        {displayedStaffList.map((item, index) => {
                          return (
                            <tr key={index}>
                              {item.deleteStatus === "0" && (
                                <>
                                  <td>{calculateCount(index)}</td>
                                  <td>{item.lastName}</td>
                                  <td>{item.firstName}</td>
                                  <td>
                                    {item.status === "Inactive" ? (
                                      <Badge bg="danger" className="badgeC">
                                        <GoDotFill size={15} /> Inactive
                                      </Badge>
                                    ) : (
                                      <Badge bg="success" className="badgeC">
                                        <GoDotFill size={15} /> Active
                                      </Badge>
                                    )}
                                  </td>
                                  <td>{item.accountNumber}</td>
                                  <td>{item.Position}</td>
                                  <td>{item.Level}</td>
                                  <td>
                                    {item.email && item.email.length >= 20
                                      ? item.email.slice(0, 20) + "..."
                                      : item.email}
                                  </td>

                                  <td>
                                    {moment(item.updatedAt).format(
                                      "DD.MM.YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <div className="mb-2">
                                      {["start"].map((direction) => (
                                        <DropdownButton
                                          as={ButtonGroup}
                                          key={direction}
                                          id={`dropdown-button-drop-${direction}`}
                                          drop={direction}
                                          className="tabledrop_btn"
                                          title={<FiMoreVertical />}
                                        >
                                          <Dropdown.Item
                                            eventKey="1"
                                            className="menudrop_link"
                                            onClick={() =>
                                              handleSendEmailClick(
                                                item.firstName,
                                                item.email,
                                                item._id
                                              )
                                            }
                                          >
                                            <Image
                                              className="editimg"
                                              src={SendEmailIcon}
                                              alt=""
                                            />{" "}
                                            Send email
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            eventKey="2"
                                            className="menudrop_link"
                                            onClick={() =>
                                              handleEditClick(item)
                                            }
                                          >
                                            <Image
                                              className="editimgt"
                                              src={EditIcon}
                                              alt=""
                                            />{" "}
                                            Edit
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            eventKey="3"
                                            className="menucancel_link"
                                            onClick={() =>
                                              handleBlockClick(
                                                item._id,
                                                item.status
                                              )
                                            }
                                          >
                                            <Image
                                              className="editimgt"
                                              src={BlockIcon}
                                              alt=""
                                            />{" "}
                                            {item.status === "Active"
                                              ? "Block"
                                              : "Unblock"}
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            eventKey="4"
                                            className="menucancel_link"
                                            onClick={() =>
                                              handleDeleteClick(item._id)
                                            }
                                          >
                                            <Image
                                              className="editimgt"
                                              src={TrashIcon}
                                              alt=""
                                            />{" "}
                                            Delete
                                          </Dropdown.Item>
                                        </DropdownButton>
                                      ))}
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Pagination controls */}
            <div className="pagination-container">
              <div className="paginationFeature">
                <p>Rows per page: 10</p>
              </div>
              <div className="pagination">
                <p>
                  {currentPage} - {displayedStaffList.length} of{" "}
                  {staffList.length}
                </p>
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={staffList.length}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination"
                />
              </div>
            </div>
            <ToastContainer />
          </Container>
        </div>
      </div>
      <StaffModal
        show={modalShow}
        onShowOtherModal={() => {
          setModalShow(false);
        }}
        onHide={() => {
          setModalShow(false);
        }}
      />
      <EditStaffModal
        itemdetail={editDetail || {}}
        show={editModalShow}
        onShowOtherModal={() => {
          setEditModalShow(false);
        }}
        onHide={() => {
          setEditModalShow(false);
        }}
      />
    </>
  );
};

// Add Modal
function StaffModal(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [staffemail, setStaffEmail] = useState("");
  const [position, setPosition] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [staffPhone, setStaffPhone] = useState("");
  const [accountNumber, setAccountNUmer] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [managerFirstName, setManagerFirstName] = useState("");
  const [managerLastName, setManagerLastName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");

  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const { _id, email } = userDeatildata;
  const { setIsLoading } = useContext(LoadingContext);

  const [firstNameError, setFirstNameError] = useState(0);
  const [lastNameError, setLastNameError] = useState(0);
  const [staffemailError, setStaffEmailError] = useState(0);
  const [positionError, setPositionError] = useState(0);
  const [selectedLevelError, setSelectedLevelError] = useState(0);

  const [staffPhoneError, setStaffPhoneError] = useState(0);
  const [accountNumberError, setAccountNUmerError] = useState(0);
  const [managerFirstNameError, setManagerFirstNameError] = useState(0);
  const [managerLastNameError, setManagerLastNameError] = useState(0);
  const [managerEmailError, setManagerEmailError] = useState(0);
  const [managerPhoneError, setManagerPhoneError] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const handleRadioChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!firstName) {
      errorCount++;
      let msg = "Please enter first name.";
      setFirstNameError(msg);
    } else {
      setFirstNameError(null);
    }

    if (!lastName) {
      errorCount++;
      let msg = "Please enter last name.";
      setLastNameError(msg);
    } else {
      setLastNameError(null);
    }

    if (!position) {
      errorCount++;
      let msg = "Please enter position.";
      setPositionError(msg);
    } else {
      setPositionError(null);
    }

    let staffemailError = SERVICE.validateEmail(staffemail);
    if (staffemailError !== undefined) {
      errorCount++;
      let msg =
        staffemailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setStaffEmailError(msg);
    } else {
      setStaffEmailError(null);
    }

    if (!selectedLevel) {
      errorCount++;
      let msg = "Please select level.";
      setSelectedLevelError(msg);
    } else {
      setSelectedLevelError(null);
    }

    if (!staffPhone) {
      errorCount++;
      let msg = "Please enter phone";
      setStaffPhoneError(msg);
    } else {
      setStaffPhoneError(null);
    }

    // if (!accountNumber) {
    //   errorCount++;
    //   let msg = "Please enter account number";
    //   setAccountNUmerError(msg);
    // } else {
    //   setAccountNUmerError(null);
    // }

    // if (!companyName) {
    //   errorCount++;
    //   let msg = "Please enter company name";
    //   setCompanyNameError(msg);
    // } else {
    //   setCompanyNameError(null);
    // }

    // if (!address) {
    //   errorCount++;
    //   let msg = "Please enter address";
    //   setAddressError(msg);
    // } else {
    //   setAddressError(null);
    // }

    if (!managerFirstName) {
      errorCount++;
      let msg = "Please enter first name";
      setManagerFirstNameError(msg);
    } else {
      setManagerFirstNameError(null);
    }

    if (!managerLastName) {
      errorCount++;
      let msg = "Please enter last name";
      setManagerLastNameError(msg);
    } else {
      setManagerLastNameError(null);
    }

    if (!managerPhone) {
      errorCount++;
      let msg = "Please enter phone number";
      setManagerPhoneError(msg);
    } else {
      setManagerPhoneError(null);
    }

    let managerEmailError = SERVICE.validateEmail(managerEmail);
    if (managerEmailError !== undefined) {
      errorCount++;
      let msg =
        managerEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setManagerEmailError(msg);
    } else {
      setManagerEmailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = (props) => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("errorCount", errorCount);
      return;
    } else {
      addStaffDetails(props);
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [
    firstName,
    lastName,
    staffemail,
    position,
    selectedLevel,
    staffPhone,
    // accountNumber,
    managerFirstName,
    managerLastName,
    managerEmail,
    managerPhone,
  ]);

  const addStaffDetails = (item) => {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      Position: position,
      email: staffemail,
      Level: selectedLevel,
      adminId: _id,
      contactCode: selectedCountryCode,
      accountOwnerCode: unLoadingCountryCode,
      phone: staffPhone,
      managerFirstName: managerFirstName,
      managerLastName: managerLastName,
      managerEmail: managerEmail,
      mangerPhone: managerPhone,
      companyName: companyName,
      duns: accountNumber,
      address: address,
      Link: `${URL_LINK}staffLogin`,
    };

    setIsLoading(true);
    addStaff(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Staff has been successfully added.");
          setTimeout(() => {
            item.onHide(false);
          }, 1500);
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const handleCancel = (props) => {
    setSelectedLevel(null);
    setSubmit(false);
    setTimeout(() => {
      props.onHide();
    }, 100);
  };

  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Add staff</h2>
        <BreadCrumbHeader image={UserInfoIcon} title="STAFF INFORMATION" />

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="First name"
              placeholder="Enter first name"
              type="text"
              reqstart="*"
              onChange={(text) => setFirstName(text.target.value)}
            />
            {firstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {firstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Last name"
              placeholder="Enter last name"
              type="text"
              reqstart="*"
              onChange={(text) => setLastName(text.target.value)}
            />
            {lastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {lastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Email"
              placeholder="Enter an Email"
              type="text"
              reqstart="*"
              onChange={(text) => setStaffEmail(text.target.value)}
            />
            {staffemailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {staffemailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                onChange={(text) => setStaffPhone(text.target.value)}
              />
            </div>
            {staffPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {staffPhoneError}
              </div>
            ) : null}
          </Col>
          {/* <Col md={6}>
            <TextInputWithLabel
              label="DUNS"
              placeholder="Enter DUNS"
              type="text"
              reqstart="*"
              onChange={(text) => setAccountNUmer(text.target.value)}
            />
            {accountNumberError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountNumberError}
              </div>
            ) : null}
          </Col> */}
          <Col md={6}>
            <TextInputWithLabel
              label="Position"
              placeholder="Enter an Position"
              type="text"
              reqstart="*"
              onChange={(text) => setPosition(text.target.value)}
            />
            {positionError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {positionError}
              </div>
            ) : null}
          </Col>

          {/* <Col md={6}>
            <TextInputWithLabel
              label="Company"
              placeholder="Enter company name"
              type="text"
              reqstart="*"
              onChange={(text) => setCompanyName(text.target.value)}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col> */}

          {/* <Col md={6}>
            <TextInputWithLabel
              label="Address"
              placeholder="Enter address"
              type="text"
              reqstart="*"
              onChange={(text) => setAddress(text.target.value)}
            />
            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col> */}

          <Col md={6}>
            <TextInputWithLabel
              label="Manager First Name"
              placeholder="Enter manager first name"
              type="text"
              reqstart="*"
              onChange={(text) => setManagerFirstName(text.target.value)}
            />
            {managerFirstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerFirstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Manager Last Name"
              placeholder="Enter manager last name"
              type="text"
              reqstart="*"
              onChange={(text) => setManagerLastName(text.target.value)}
            />
            {managerLastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerLastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Manager Email"
              placeholder="Enter manager email"
              type="text"
              reqstart="*"
              onChange={(text) => setManagerEmail(text.target.value)}
            />
            {managerEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Manager Phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                onChange={(text) => setManagerPhone(text.target.value)}
              />
            </div>
            {managerPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerPhoneError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="custom_radio">
          <h5>
            Level <span className="requiresLable">*</span>
          </h5>
          <label>
            <input
              type="radio"
              name="product"
              value="1"
              className="card-input-element"
              onChange={handleRadioChange}
              checked={selectedLevel === "1"}
            />
            <div className="card-input">
              <div className="panel-heading">1</div>
            </div>
          </label>

          <label>
            <input
              type="radio"
              name="product"
              value="2"
              className="card-input-element"
              onChange={handleRadioChange}
              checked={selectedLevel === "2"}
            />
            <div className="card-input">
              <div className="panel-heading">2</div>
            </div>
          </label>

          <label>
            <input
              type="radio"
              name="product"
              value="3"
              className="card-input-element"
              onChange={handleRadioChange}
              checked={selectedLevel === "3"}
            />
            <div className="card-input">
              <div className="panel-heading">3</div>
            </div>
          </label>
          {selectedLevelError && Submit ? (
            <div
              style={{
                fontFamily: "AvenirLTProLight",
                color: "#FF0000",
                opacity: 0.6,
              }}
            >
              {selectedLevelError}
            </div>
          ) : null}
        </div>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={() => handleCancel(props)}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => onSubmit(props)}
          >
            Create this staff
          </Button>
          <ToastContainer />
        </div>
      </Modal.Body>
    </Modal>
  );
}

// Edit Modal
function EditStaffModal(props) {
  const [editfirstName, setFirstName] = useState("");
  const [editlastName, setLastName] = useState("");
  const [staffemail, setStaffEmail] = useState("");
  const [position, setPosition] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [staffPhone, setStaffPhone] = useState("");
  const [staffaccountNumber, setAccountNumber] = useState("");
  const [staffCompanyName, setCompanyName] = useState("");
  const [staffAddress, setAddress] = useState("");
  const [managerFirstName, setManagerFirstName] = useState("");
  const [managerLastName, setManagerLastName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const { setIsLoading } = useContext(LoadingContext);
  const [firstNameError, setFirstNameError] = useState(0);
  const [lastNameError, setLastNameError] = useState(0);
  const [staffemailError, setStaffEmailError] = useState(0);
  const [positionError, setPositionError] = useState(0);
  const [selectedLevelError, setSelectedLevelError] = useState(0);
  const [staffPhoneError, setStaffPhoneError] = useState(0);
  const [accountNumberError, setAccountNUmerError] = useState(0);
  const [managerFirstNameError, setManagerFirstNameError] = useState(0);
  const [managerLastNameError, setManagerLastNameError] = useState(0);
  const [managerEmailError, setManagerEmailError] = useState(0);
  const [managerPhoneError, setManagerPhoneError] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const handleRadioChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const {
    _id,
    accountNumber,
    createdAt,
    email,
    firstName,
    lastName,
    Position,
    Level,
    accountOnwerLastName,
    accountOnwerName,
    accountOwnerCode,
    accountPhoneNumber,
    address,
    companyName,
    contactCode,
    duns,
    mangerEmail,
    mangerPhone,
  } = props.itemdetail || {};

  useEffect(() => {
    if (props.itemdetail) {
      setFirstName(firstName);
      setLastName(lastName);
      setStaffEmail(email);
      setPosition(Position);
      setSelectedLevel(Level);
      setAddress(address);
      setCompanyName(companyName);
      setSelectedCountryCode(contactCode);
      setManagerFirstName(accountOnwerName);
      setManagerLastName(accountOnwerLastName);
      setManagerEmail(mangerEmail);
      setManagerPhone(mangerPhone);
      setAccountNumber(duns);
      setUnLoadingCountryCode(accountOwnerCode);
      setStaffPhone(accountPhoneNumber);
    }
  }, [props.itemdetail]);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!editfirstName) {
      errorCount++;
      let msg = "Please enter first name.";
      setFirstNameError(msg);
    } else {
      setFirstNameError(null);
    }

    if (!editlastName) {
      errorCount++;
      let msg = "Please enter last name.";
      setLastNameError(msg);
    } else {
      setLastNameError(null);
    }

    if (!position) {
      errorCount++;
      let msg = "Please enter position.";
      setPositionError(msg);
    } else {
      setPositionError(null);
    }

    let staffemailError = SERVICE.validateEmail(staffemail);
    if (staffemailError !== undefined) {
      errorCount++;
      let msg =
        staffemailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setStaffEmailError(msg);
    } else {
      setStaffEmailError(null);
    }

    if (!selectedLevel) {
      errorCount++;
      let msg = "Please Select Level.";
      setSelectedLevelError(msg);
    } else {
      setSelectedLevelError(null);
    }

    if (!staffPhone) {
      errorCount++;
      let msg = "Please enter phone";
      setStaffPhoneError(msg);
    } else {
      setStaffPhoneError(null);
    }

    // if (!accountNumber) {
    //   errorCount++;
    //   let msg = "Please enter account number";
    //   setAccountNUmerError(msg);
    // } else {
    //   setAccountNUmerError(null);
    // }

    // if (!staffCompanyName) {
    //   errorCount++;
    //   let msg = "Please enter company name";
    //   setCompanyNameError(msg);
    // } else {
    //   setCompanyNameError(null);
    // }

    // if (!staffAddress) {
    //   errorCount++;
    //   let msg = "Please enter address";
    //   setAddressError(msg);
    // } else {
    //   setAddressError(null);
    // }

    if (!managerFirstName) {
      errorCount++;
      let msg = "Please enter first name";
      setManagerFirstNameError(msg);
    } else {
      setManagerFirstNameError(null);
    }

    if (!managerLastName) {
      errorCount++;
      let msg = "Please enter last name";
      setManagerLastNameError(msg);
    } else {
      setManagerLastNameError(null);
    }

    if (!managerPhone) {
      errorCount++;
      let msg = "Please enter phone number";
      setManagerPhoneError(msg);
    } else {
      setManagerPhoneError(null);
    }

    let managerEmailError = SERVICE.validateEmail(managerEmail);
    if (managerEmailError !== undefined) {
      errorCount++;
      let msg =
        managerEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setManagerEmailError(msg);
    } else {
      setManagerEmailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = (props) => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("errorCount", errorCount);
      return;
    } else {
      editStaffDetail(props);
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [editfirstName, editlastName, staffemail, position, selectedLevel]);

  const editStaffDetail = (item) => {
    const payload = {
      firstName: editfirstName,
      lastName: editlastName,
      Position: position,
      email: staffemail,
      Level: selectedLevel,
      addid: _id,
      contactCode: selectedCountryCode,
      accountOwnerCode: unLoadingCountryCode,
      phone: staffPhone,
      managerFirstName: managerFirstName,
      managerLastName: managerLastName,
      managerEmail: managerEmail,
      mangerPhone: managerPhone,
      companyName: staffCompanyName,
      duns: staffaccountNumber,
      address: staffAddress,
    };

    setIsLoading(true);
    staff_EditDetail(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Staff details have been updated successfully.");
          setTimeout(() => {
            item.onHide(false);
          }, 1500);
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Edit Staff</h2>
        <BreadCrumbHeader image={UserInfoIcon} title="STAFF INFORMATION" />
        <Row>
          <Col md={6}>
            <EditFieldTextInput
              label="First name"
              placeholder="Enter first name"
              type="text"
              reqstart="*"
              value={editfirstName}
              onChange={(text) => setFirstName(text)}
            />
            {firstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {firstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Last name"
              placeholder="Enter last name"
              type="text"
              reqstart="*"
              value={editlastName}
              onChange={(text) => setLastName(text)}
            />
            {lastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {lastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Email"
              placeholder="Enter an Email"
              type="text"
              reqstart="*"
              value={staffemail}
              onChange={(text) => setStaffEmail(text)}
            />
            {staffemailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {staffemailError}
              </div>
            ) : null}
          </Col>

          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <EditFieldTextInput
                label="Phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={staffPhone}
                onChange={(text) => setStaffPhone(text)}
              />
            </div>
            {staffPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {staffPhoneError}
              </div>
            ) : null}
          </Col>

          {/* <Col md={6}>
            <EditFieldTextInput
              label="DUNS"
              placeholder="Enter DUNS"
              type="text"
              reqstart="*"
              value={staffaccountNumber}
              onChange={(text) => setAccountNumber(text)}
            />
            {accountNumberError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountNumberError}
              </div>
            ) : null}
          </Col> */}

          <Col md={6}>
            <EditFieldTextInput
              label="Position"
              placeholder="Enter an Position"
              type="text"
              reqstart="*"
              value={position}
              onChange={(text) => setPosition(text)}
            />
            {positionError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {positionError}
              </div>
            ) : null}
          </Col>

          {/* <Col md={6}>
            <EditFieldTextInput
              label="Company"
              placeholder="Enter company name"
              type="text"
              reqstart="*"
              value={staffCompanyName}
              onChange={(text) => setCompanyName(text)}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col> */}

          {/* <Col md={6}>
            <EditFieldTextInput
              label="Address"
              placeholder="Enter address"
              type="text"
              reqstart="*"
              value={staffAddress}
              onChange={(text) => setAddress(text)}
            />
            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col> */}

          <Col md={6}>
            <EditFieldTextInput
              label="Manager First Name"
              placeholder="Enter manager first name"
              type="text"
              reqstart="*"
              value={managerFirstName}
              onChange={(text) => setManagerFirstName(text)}
            />
            {managerFirstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerFirstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Manager Last Name"
              placeholder="Enter manager last name"
              type="text"
              reqstart="*"
              value={managerLastName}
              onChange={(text) => setManagerLastName(text)}
            />
            {managerLastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerLastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Manager Email"
              placeholder="Enter manager email"
              type="text"
              reqstart="*"
              value={managerEmail}
              onChange={(text) => setManagerEmail(text)}
            />
            {managerEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <EditFieldTextInput
                label="Manager Phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={managerPhone}
                onChange={(text) => setManagerPhone(text)}
              />
            </div>
            {managerPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {managerPhoneError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="custom_radio">
          <h5>
            Level <span className="requiresLable">*</span>
          </h5>
          <label>
            <input
              type="radio"
              name="product"
              value="1"
              className="card-input-element"
              onChange={handleRadioChange}
              checked={selectedLevel === "1"}
            />
            <div className="card-input">
              <div className="panel-heading">1</div>
            </div>
          </label>

          <label>
            <input
              type="radio"
              name="product"
              value="2"
              className="card-input-element"
              onChange={handleRadioChange}
              checked={selectedLevel === "2"}
            />
            <div className="card-input">
              <div className="panel-heading">2</div>
            </div>
          </label>

          <label>
            <input
              type="radio"
              name="product"
              value="3"
              className="card-input-element"
              onChange={handleRadioChange}
              checked={selectedLevel === "3"}
            />
            <div className="card-input">
              <div className="panel-heading">3</div>
            </div>
          </label>
          {selectedLevelError && Submit ? (
            <div
              style={{
                fontFamily: "AvenirLTProLight",
                color: "#FF0000",
                opacity: 0.6,
              }}
            >
              {selectedLevelError}
            </div>
          ) : null}
        </div>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.onHide}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => onSubmit(props)}
          >
            Save Changes
          </Button>
          <ToastContainer />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Team;
