import axios from "axios";
import { BASE_URL } from "./env";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 90000,
});

const blob_axios = axios.create({
  baseURL: BASE_URL,
  responseType: "blob",
  timeout: 90000,
});

export default { instance, blob_axios };
