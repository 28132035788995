import { useContext, useState } from "react";
import { Col, Row, Button, Table, Image, Modal, Form } from "react-bootstrap";
import TaskIcon from "../images/task.svg";
import TruckWhiteIcon from "../images/truckwhite.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import { FaStar, FaRegStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { add_Quote } from "../services/ApiCalls";
import { QuotesDataContext } from "../services/QuotesData";
import moment from "moment";
import Loader from "../../common/component/Loader";
import ReactStarsRating from "react-awesome-stars-rating";
import { AppStoreContext } from "../../store/AppStore";
import consoleService from "../../utility/services/consoleService";

const NewQouteModalOneTHree = (props) => {
  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const {
    _id,
    email,
    firstName,
    lastName,
    accountOnwerFirstName,
    accountOnwerLastName,
    profile,
  } = userDeatildata;

  const role = profile;
  const isRoleStaff = role == "Staff";

  const [newQuoteLoading, setNewQuoteLoading] = useState(false);

  const {
    activeTab,
    //Loading hooks
    pickupLocation,
    setPickupLocation,
    reference,
    setReference,
    companyName,
    setCompanyName,
    address,
    setAddress,
    date,
    setDate,
    time,
    setTime,
    contactName,
    setContactName,
    contactPhone,
    setContactPhone,
    contactEmail,
    setContactEmail,
    loadingLatLong,
    setLoadingLatLong,
    //delivery hooks
    deliveryLocation,
    setDeliveryLocation,
    deliveryType,
    setDeliveryType,
    deliveryRefernce,
    setDeliveryRefernce,
    deliveryCompanyName,
    setDeliveryCompanyName,
    deliveryAddress,
    setDeliveryAddress,
    deliveryDate,
    setDeliveryDate,
    deliveryTime,
    setDeliveryTime,
    deliveryContactName,
    setDeliveryContactName,
    deliveryContactPhone,
    setDeliveryContactPhone,
    deliveryContactEmail,
    setDeliveryContactEmail,
    deliveryLatLong,
    setDeliveryLatLong,
    // address
    loadingState,
    setLoadingState,
    loadingCity,
    setLoadingCity,
    loadingCountry,
    setLoadingCountry,
    loadingPostcode,
    setLoadingPostcode,
    deliveryLoadingState,
    setDeliveryLoadingState,
    deliveryLoadingCity,
    setDeliveryLoadingCity,
    deliveryLoadingPostcode,
    setDeliveryLoadingPostcode,
    deliveryLoadingCountry,
    setDeliveryLoadingCountry,
    //General cargo
    setMaterialName,
    setNumberOfPallets,
    setSizeOfPallets,
    setHeightOfPallets,
    setTotalWeightOfPallets,
    otherInformation,
    setOtherInformation,
    // tanker
    setQuantity,
    setSelectOptions,
    // refrigerated
    setTemperature,
    setSelectMsds,
    // carrier
    selectedCarriers,
    selectedCarrierIds,
    // add
    materials,
    setMaterials,
    savedMaterials,
    setSavedMaterials,
    refrigeratedMaterials,
    setRefrigeratedMaterials,
    // phoneNumberCode
    selectedCountryCode,
    setSelectedCountryCode,
    unLoadingCountryCode,
    setUnLoadingCountryCode,
    // For Staff - Search a client
    selectedCarrierID,
    setSelectedCarrierID,
    // submit
    setSubmit,
  } = useContext(QuotesDataContext);

  const onSubmit = () => {
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setOtherInformation("");
    setQuantity("");
    setSelectOptions([]);
    setTemperature("");
    setSelectMsds("");
    //
    setReference("");
    setCompanyName("");
    setAddress("");
    setDate("");
    setTime("");
    setContactName("");
    setContactPhone("");
    setContactEmail("");
    setLoadingLatLong({});
    //
    setDeliveryType("");
    setDeliveryRefernce("");
    setDeliveryCompanyName("");
    setDeliveryAddress("");
    setDeliveryDate("");
    setDeliveryTime("");
    setDeliveryContactName("");
    setDeliveryContactPhone();
    setDeliveryContactEmail("");
    setDeliveryLatLong({});
    //address
    setLoadingState("");
    setLoadingCity("");
    setLoadingCountry("");
    setLoadingPostcode("");
    setDeliveryLoadingState("");
    setDeliveryLoadingCity("");
    setDeliveryLoadingPostcode("");
    setDeliveryLoadingCountry();
    setDeliveryLocation(false);
    setPickupLocation(false);
    // add
    setMaterials([]);
    setSavedMaterials([]);
    setRefrigeratedMaterials([]);
    //Phone code
    setSelectedCountryCode("");
    setUnLoadingCountryCode("");
    // For Staff - Search a client
    setSelectedCarrierID("");
    setSubmit(false);
  };

  // Add Qoute API
  const addNewQute = (item) => {
    let materialInformation;
    if (activeTab === "General cargo") {
      materialInformation = materials;
    } else if (activeTab === "Tanker") {
      materialInformation = savedMaterials;
    } else if (activeTab === "Refrigerated") {
      materialInformation = refrigeratedMaterials;
    }
    const applicant_name = isRoleStaff
      ? `${firstName} ${lastName}`
      : `${userDetail?.companyName}`;

    const payload = {
      StaffId: _id,
      category: activeTab,
      userId: _id,
      MaterialInformation: materialInformation,
      OtherInformation: otherInformation,
      LOADING_INFORMATIONS: [
        {
          Reference: reference,
          Company_name: companyName,
          Address: {
            type: "Point",
            coordinates: [loadingLatLong.lng, loadingLatLong.lat],
          },
          Date: date,
          Hours: time,
          Contact_name: contactName,

          Contact_phone: contactPhone,
          Contact_email: contactEmail,
          appoitment_location: pickupLocation,
          city: address,
          loadingcode: selectedCountryCode,
          loadingState: loadingState,
          loadingCity: loadingCity,
          loadingCountry: loadingCountry,
          loadingPostcode: loadingPostcode,
        },
      ],
      DELIVERY_INFORMATIONS: [
        {
          appoitment_location_delivery: deliveryLocation,
          Delivery_type: deliveryType,
          Reference: deliveryRefernce,
          Company_name: deliveryCompanyName,
          Address: {
            type: "Point",
            coordinates: [deliveryLatLong.lng, deliveryLatLong.lat],
          },
          delivery_Date: deliveryDate,
          delivery_Hour: deliveryTime,
          deliver_Contact_name: deliveryContactName,
          deliver_contact_phone: deliveryContactPhone,
          delivery_conntact_email: deliveryContactEmail,
          delivery_city: deliveryAddress,
          delvieryCode: unLoadingCountryCode,
          deliveryState: deliveryLoadingState,
          deliveryCity: deliveryLoadingCity,
          deliveryCountry: deliveryLoadingCountry,
          deliveryPostcode: deliveryLoadingPostcode,
        },
      ],
      ChooseCarrier: selectedCarrierIds,
      carrier_email: email,
      direct_email: "",
      carrier: selectedCarriers[0].companyName,
      directRequests: "no",
      Latitude: "31.2980035714754",
      Longitudes: "75.56183075264784",
      loading_Latitude: loadingLatLong.lat,
      loading_Longitudes: loadingLatLong.lng,
      delevry_Latitude: deliveryLatLong.lat,
      delevry_Longitudes: deliveryLatLong.lng,
      prize: "0",
      applicant: applicant_name,
      type: isRoleStaff ? "staff" : "user",
    };
    if (isRoleStaff) {
      payload["client_id"] = selectedCarrierID;
    }
    setNewQuoteLoading(true);
    add_Quote(payload)
      .then((res) => {
        if (res.status === true) {
          onSubmit();
          toast("Quote Added Successfully ");
          setTimeout(() => {
            setNewQuoteLoading(false);
            item.onShowOtherModal(false);
          }, 1500);
        } else {
          toast(res.msg);
          setNewQuoteLoading(false);
        }
      })
      .catch((err) => {
        setNewQuoteLoading(false);
        console.log("err", err);
      });
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>New quote</h2>
        <BreadCrumbHeader image={TaskIcon} title="ORDER SUMMARY" />
        <div className="braedCrumbNoticon mb-1">
          <h4>Vehicle category</h4>
          <h4>{activeTab}</h4>
        </div>

        <Row>
          <Col md={6} lg={6}>
            <div className="loadingBox">
              <h3 className="loading_head">Loading informations</h3>
              <div className="load_body">
                <div className="loadingBox_flex">
                  <h3>
                    Reference : <strong>{reference}</strong>
                  </h3>
                  <h3>
                    Date : <strong>{moment(date).format("DD.MM.YYYY")}</strong>
                  </h3>
                  <h3>
                    Time : <strong>{time}</strong>
                  </h3>
                </div>

                <h3>
                  <strong>
                    {" "}
                    {pickupLocation === true
                      ? `Appointment needed`
                      : "Appointment not required"}
                  </strong>
                </h3>

                <h3 className="mt-3">Address</h3>

                <h3 className="loadingBox_address ">
                  <strong>
                    {address} <br />
                  </strong>
                </h3>
                <h3 className="mt-3">
                  Contact : <strong>{contactName}</strong>
                </h3>

                <div className="loadingBox_flex">
                  <h3>
                    Phone :{" "}
                    <strong> {selectedCountryCode + " " + contactPhone}</strong>
                  </h3>
                  <h3>
                    Email : <strong>{contactEmail}</strong>
                  </h3>
                </div>
              </div>
            </div>
          </Col>

          <Col md={6} lg={6}>
            <div className="loadingBox">
              <div
                className="d-flex align-items-center justify-content-between p-2 px-2"
                style={{ backgroundColor: "#e9efff" }}
              >
                <h3 className="loading_head p-0">Unloading informations</h3>
                <h3 className="mb-0">
                  <h3 style={{ marginBottom: "0", marginTop: 1 }}>
                    Delivery : <strong>{deliveryType}</strong>
                  </h3>
                </h3>
              </div>
              <div className="load_body">
                <div className="loadingBox_flex">
                  <h3>
                    Reference : <strong>{deliveryRefernce}</strong>
                  </h3>
                  <h3>
                    Date :{" "}
                    <strong>{moment(deliveryDate).format("DD.MM.YYYY")}</strong>
                  </h3>
                  <h3>
                    Time : <strong>{deliveryTime}</strong>
                  </h3>
                </div>

                <h3>
                  <strong>
                    {deliveryLocation === true
                      ? `Appointment needed`
                      : "Appointment not required"}
                  </strong>
                </h3>

                <h3 className="mt-3">Address</h3>

                <h3 className="loadingBox_address ">
                  <strong>
                    {deliveryAddress} <br />
                  </strong>
                </h3>

                <div className="loadingBox_flex mt-3">
                  <h3>
                    Contact : <strong>{deliveryContactName}</strong>
                  </h3>
                </div>

                <div className="loadingBox_flex">
                  <h3>
                    Phone :{" "}
                    <strong>
                      {unLoadingCountryCode + " " + deliveryContactPhone}
                    </strong>
                  </h3>
                  <h3>
                    Email : <strong>{deliveryContactEmail}</strong>
                  </h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="matTable">
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Material informations</th>
                {activeTab === "Refrigerated" && (
                  <th className="text-center">{"Temperature"}</th>
                )}
                <th className="text-center">{"Weight"}</th>
                {activeTab === "Tanker" ? (
                  ""
                ) : (
                  <th className="text-center">{"Nb of pallets"}</th>
                )}
                {activeTab === "Tanker" ? (
                  ""
                ) : (
                  <th className="text-center">{"Size of pallets"}</th>
                )}
                {activeTab === "Tanker" ? (
                  ""
                ) : (
                  <th className="text-center">{"Height"}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {/* Tanker  */}
              {activeTab === "Tanker" &&
                savedMaterials.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <strong>{item.materialName}</strong>
                          {/* eslint-disable-next-line */}
                          {item.ADR == "Yes" && (
                            <div style={{ marginTop: 5 }}>
                              ADR : {item.adrReference} <br />
                            </div>
                          )}
                          <div className="tdFlex">
                            {item.selectedOptions.map((item, index) => {
                              return (
                                <div style={{ marginRight: 10 }}>
                                  {item === "MSDS included"
                                    ? "MSDS included"
                                    : item === "Blacklisted"
                                    ? "Blacklisted	"
                                    : item === "Cleaning certificate"
                                    ? "Cleaning certificate"
                                    : item === "Pump needed"
                                    ? "Pump needed"
                                    : "-"}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {item.quantity} L
                        </td>
                      </tr>
                    </>
                  );
                })}

              {/* General cargo */}
              {activeTab === "General cargo" &&
                materials.map((item, index) => {
                  return (
                    <tr className="oddtr" key={index}>
                      <td>{item.materialName}</td>
                      <td className="text-center">
                        {item.totalWeightOfPallets} Kg
                      </td>
                      <td className="text-center">{item.numberOfPallets}</td>
                      <td className="text-center">{item.sizeOfPallets}</td>
                      <td className="text-center">{item.heightOfPallets}</td>
                    </tr>
                  );
                })}

              {/* Refrigerated */}
              {activeTab === "Refrigerated" &&
                refrigeratedMaterials.map((item, index) => {
                  return (
                    <tr className="oddtr" key={index}>
                      <td>
                        <strong>{item.materialName}</strong>
                        <br />
                        {item.msds === "Yes" ? "MSDS included" : ""}
                      </td>
                      {activeTab === "Refrigerated" && (
                        <td className="text-center">{item.temprature} °C</td>
                      )}
                      <td className="text-center">
                        {item.totalWeightOfPallets} Kg
                      </td>
                      <td className="text-center">{item.numberOfPallets}</td>
                      <td className="text-center">{item.sizeOfPallets}</td>
                      <td className="text-center">{item.heightOfPallets}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        <div className="braedCrumbNoticon mb-2">
          <h4>Other informations </h4>
        </div>

        <Form.Group className="mb-3">
          <Form.Control
            style={{ borderRadius: 2 }}
            as="textarea"
            rows={3}
            value={otherInformation}
            onChange={(text) => setOtherInformation(text.target.value)}
          />
        </Form.Group>

        <div className="braedCrumbNoticon mb-2">
          <h4>Carriers selection</h4>
        </div>

        <div className="carrieBoxMain carrieBoxMainFlex">
          {selectedCarriers.map((item, index) => {
            const average_rating =
              item.average_rating > 0 ? item.average_rating.toFixed(1) : 0;
            const complete_missions = item.complete_missions;
            const missions_txt =
              item.complete_missions > 1 ? "missions" : "mission";

            return (
              <div className="carrieBox" key={index}>
                <div className="iconBox">
                  <Image src={TruckWhiteIcon} alt="" className="img-fluid" />
                </div>
                <div className="car_right">
                  <h5>
                    {role === "Staff"
                      ? `${item.companyName} (${item.number_plate})`
                      : `${item.accountNumber} (${item.number_plate})`}
                  </h5>

                  <div className="startFlex">
                    <ReactStarsRating
                      className={"Star_rating"}
                      isHalf={true}
                      size={16}
                      starGap={0}
                      isEdit={false}
                      value={average_rating}
                    />
                    <span>{average_rating}/5</span>
                  </div>
                  <h5>
                    {complete_missions} {missions_txt}
                  </h5>
                </div>
              </div>
            );
          })}
        </div>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.close}
          >
            Back
          </Button>

          <Button
            style={{ width: 230 }}
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => addNewQute(props)}
            disabled={newQuoteLoading}
          >
            {newQuoteLoading ? <Loader /> : " Confirm quote request"}
          </Button>
        </div>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};
export default NewQouteModalOneTHree;
