import React from "react"
import { Form } from "react-bootstrap"

const NewTextInputWithLabel = (props) => {
  return (
    <Form.Group className="mb-3" style={props.inputStyle}>
      <Form.Label>
        {props.label}
        <span className="requiresLable">{props.reqstart}</span>
      </Form.Label>
      <Form.Control
        onClick={props.onClick}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        onClear={props.onClear}
        value={props.value}
        readOnly={props.readOnly}
      />
    </Form.Group>
  )
}

export default NewTextInputWithLabel
