import React from "react";
import consoleService from "../../utility/services/consoleService";
import AxiosConfig from "./AxiosConfig";

const CustomHookFunctions = () => {
  const Blob_Axios = AxiosConfig.blob_axios;
  const Paginated_data = (page_no, limit, array_of_data) => {
    const PageNo = Number(page_no);
    const Limit = Number(limit);
    const skip = (PageNo - 1) * Limit;
    const show_up_to = skip + Limit;
    const result = array_of_data.slice(skip, show_up_to);
    const array_length = array_of_data.length;
    const to =
      Limit > array_length
        ? array_length
        : show_up_to > array_length
        ? array_length
        : show_up_to;
    const from = array_length > 0 ? skip + 1 : 0;
    return { result, page_no: PageNo, to, from, total_docs: array_length };
  };
  const isValidString = (string) => {
    return (
      string != "null" &&
      string != "Null" &&
      string != null &&
      string != undefined &&
      string != "undefined" &&
      string.toString().trim()
    );
  };

  const download_file_from_server = async (url) => {
    const res = await Blob_Axios.get(url);
    const blobUrl = window.URL.createObjectURL(res.data);
    const file_name = url.split("/").pop();
    const a_tag = document.createElement("a");
    a_tag.href = blobUrl;
    a_tag.setAttribute("download", file_name);
    document.body.appendChild(a_tag);
    a_tag.click();
    a_tag.remove();
  };
  return { Paginated_data, isValidString, download_file_from_server };
};

export default CustomHookFunctions;
