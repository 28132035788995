const USER_SIGN_UP = "userSignupAPI";
const USER_LOGIN = "userLoginDetail";
const USER_FORGOT_PASSWORD = "userEmailNotification";
const USER_RESET_PASSWORD = "resetPasswordUser";
const USER_DETAIL = "getUserDeatil";
const USER_EDIT_DETAIL = "editUserDetail";
const USER_QUOTE_LIST = "getUserqoutesList";
const USER_ADD_QUOTE = "addUserQutes";
const USER_ORDER_LIST = "getUserOdersDeatil";
const USER_DELETE_ORDER = "deleteUserOders";
const USER_DELETE_QUOTES = "deleteUserQuetsRequest";
const USER_SELECT_ORDER = "userFilterapi";
const USER_ARCHIVE_LIST = "getdeleteUserOdersDeatil";
const USER_LOADING_FILTER = "filterUserLoadingCategory";
const USER_VEHICLE_FILTER = "filterUserVehicleCategory";

export {
  USER_SIGN_UP,
  USER_LOGIN,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
  USER_EDIT_DETAIL,
  USER_DETAIL,
  USER_QUOTE_LIST,
  USER_ADD_QUOTE,
  USER_ORDER_LIST,
  USER_DELETE_ORDER,
  USER_DELETE_QUOTES,
  USER_SELECT_ORDER,
  USER_ARCHIVE_LIST,
  USER_LOADING_FILTER,
  USER_VEHICLE_FILTER,
};
