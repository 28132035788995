import React from "react";
import ReactLoader from "react-js-loader";

const Loader = ({ style, size, color }) => {
  const Style = style ? style : {};
  const Size = size ? size : 50;
  const Color = color ? color : "white";
  return (
    <div className="loader_view" style={{ ...Style }}>
      <ReactLoader type="ping-cube" bgColor={Color} size={Size} />
    </div>
  );
};

export default Loader;
