import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  Form,
  Container,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPlusCircle, FiMoreVertical, FiClock } from "react-icons/fi";
import tablearrow from "../images/table_arrow.svg";
import truckicon from "../images/truck_icon.svg";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import TruckIcon from "../images/truck.svg";
import LocationIcon from "../images/location.svg";
import DeliveryIcon from "../images/delivery.svg";
import MatIcon from "../images/maticon.svg";
import TaskIcon from "../images/task.svg";
import TruckWhiteIcon from "../images/truckwhite.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import TextInputWithoutLabel from "../customcomponents/TextInputWithoutLabel";
import TextInputAreaWithLabel from "../customcomponents/TextInputAreaWithLabel";
import { FaStar, FaRegStar } from "react-icons/fa";
import FilterSidebar from "../inc/FilterSidebar";
import FilterIcon from "../images/filter.svg";
import UserInfoIcon from "../images/userinfo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaRegTimesCircle } from "react-icons/fa";
import SearchIcon from "../images/searchicon.svg";
import * as SERVICE from "../services/index";
import {
  add_Quote,
  country_PhoneNumberCode,
  staff_PaticularEditDetail,
} from "../services/ApiCalls";
import { QuotesDataContext } from "../services/QuotesData";
import NewQouteModalOne from "../commonModal/NewQouteModalOne";
import { user_AddQuote } from "../services/UserApiServices/UserApiCalls";
import moment from "moment";
import LoadingContext from "../services/LoadingContext";
import Loader from "../../common/component/Loader";
import ReactStarsRating from "react-awesome-stars-rating";

import { AppStoreContext } from "../../store/AppStore";

const ViewQuoteDetails = (props, editId) => {
  const [particularEditData, setParticularEditData] = useState();
  const { userDetail } = useContext(AppStoreContext);
  const { setIsLoading } = useContext(LoadingContext);
  const userDeatildata = userDetail;
  const { _id } = userDeatildata;
  const [initial_loading, setInitialLoading] = useState(true);

  useEffect(() => {
    getParticularDetail();
  }, [props.editId]);

  const getParticularDetail = () => {
    const payload = {
      StaffId: _id,
      qoutesid: props.editId,
    };
    setInitialLoading(true);
    staff_PaticularEditDetail(payload)
      .then((res) => {
        if (res.status === true) {
          setParticularEditData(res.data);
          checkActiveTab(res.data);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const checkActiveTab = (data) => {
    if (data[0].category === "General cargo") {
      setMaterials(data && data[0].MaterialInformation);
    }
    if (data[0].category === "Tanker") {
      setSavedMaterials(data && data[0].MaterialInformation);
    }
    if (data[0].category === "Refrigerated") {
      setRefrigeratedMaterials(data && data[0].MaterialInformation);
    }
    setSelectedCountryCode(
      data && data[0].LOADING_INFORMATIONS[0].Contact_code
    );

    setUnLoadingCountryCode(
      data && data[0].DELIVERY_INFORMATIONS[0].delver_code
    );
  };

  useEffect(() => {
    if (particularEditData) {
      setActiveTab(particularEditData && particularEditData[0].category);

      setOtherInformation(
        particularEditData && particularEditData[0].OtherInformation
      );

      setReference(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Reference
      );
      setCompanyName(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Company_name
      );
      setAddress(
        particularEditData && particularEditData[0].LOADING_INFORMATIONS[0].city
      );
      setDate(
        particularEditData && particularEditData[0].LOADING_INFORMATIONS[0].Date
      );
      setTime(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Hours
      );
      setContactName(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Contact_name
      );
      setContactPhone(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Contact_phone
      );
      setContactEmail(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Contact_email
      );

      setPickupLocation(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].appoitment_location
      );
      // appoitment_location

      //
      setDeliveryType(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Delivery_type
      );
      setDeliveryRefernce(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Reference
      );
      setDeliveryCompanyName(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Company_name
      );
      setDeliveryAddress(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_city
      );
      setDeliveryDate(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_Date
      );
      setDeliveryTime(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_Hour
      );
      setDeliveryContactName(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliver_Contact_name
      );
      setDeliveryContactPhone(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliver_contact_phone
      );
      setDeliveryContactEmail(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_conntact_email
      );

      setDeliveryLocation(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0]
            .appoitment_location_delivery
      );

      setSelectedCarriers(
        particularEditData && particularEditData[0].ChooseCarrier
      );

      setDeliveryType(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Delivery_type
      );
    }
  }, [particularEditData]);

  const [activeTab, setActiveTab] = useState("");
  const [reference, setReference] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [pickupLocation, setPickupLocation] = useState(false);
  const [city, setCity] = useState("");
  const [loadingLatLong, setLoadingLatLong] = useState({});

  const [deliveryRefernce, setDeliveryRefernce] = useState("");
  const [deliveryCompanyName, setDeliveryCompanyName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryContactName, setDeliveryContactName] = useState("");
  const [deliveryContactPhone, setDeliveryContactPhone] = useState("");
  const [deliveryContactEmail, setDeliveryContactEmail] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryLatLong, setDeliveryLatLong] = useState({});
  // carrier list
  const [selectedCarriers, setSelectedCarriers] = useState([]);

  const [materialName, setMaterialName] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  // general
  const [numberOfPallets, setNumberOfPallets] = useState("");
  const [sizeOfPallets, setSizeOfPallets] = useState("");
  const [heightOfPallets, setHeightOfPallets] = useState("");
  const [totalWeightOfPallets, setTotalWeightOfPallets] = useState("");
  // tanker
  const [adrReference, setAdrReference] = useState("");
  const [quantity, setQuantity] = useState("");
  const [radio, setAdrRadio] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  // ADD Material
  const [materials, setMaterials] = useState([]);
  // Tanker
  const [savedMaterials, setSavedMaterials] = useState([]);
  // Ref
  const [refrigeratedMaterials, setRefrigeratedMaterials] = useState([]);
  // phone code
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("");

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body className={initial_loading ? "view_quote" : ""}>
        {initial_loading ? (
          <Loader color={"#F7CE6C"} size={80} />
        ) : (
          <React.Fragment>
            <h2>View quote</h2>
            <BreadCrumbHeader image={TaskIcon} title="ORDER SUMMARY" />
            <div className="braedCrumbNoticon mb-1">
              <h4>Vehicle category</h4>
              <h4>{activeTab}</h4>
            </div>

            <Row>
              <Col md={6} lg={6}>
                <div className="loadingBox">
                  <h3 className="loading_head">Loading informations</h3>
                  <div className="load_body">
                    <div className="loadingBox_flex">
                      <h3>
                        Reference : <strong>{reference}</strong>
                      </h3>
                      <h3>
                        Date :{" "}
                        <strong>{moment(date).format("DD.MM.YYYY")}</strong>
                      </h3>
                      <h3>
                        Time : <strong>{time}</strong>
                      </h3>
                    </div>
                    <h3>
                      <strong>
                        {" "}
                        {pickupLocation === true
                          ? "Appointment needed"
                          : "Appointment not required"}
                      </strong>
                    </h3>

                    <h3 className="mt-3">Address</h3>

                    <h3 className="loadingBox_address ">
                      <strong>{address}</strong>
                    </h3>
                    <h3 className="mt-3">
                      Contact : <strong>{contactName}</strong>
                    </h3>
                    <div className="loadingBox_flex">
                      <h3>
                        Phone :{" "}
                        <strong>
                          {selectedCountryCode} {contactPhone}
                        </strong>
                      </h3>
                      <h3>
                        Email : <strong>{contactEmail}</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={6} lg={6}>
                <div className="loadingBox">
                  <div
                    className="d-flex align-items-center justify-content-between p-2 px-2"
                    style={{ backgroundColor: "#e9efff" }}
                  >
                    <h3 className="loading_head p-0 ">
                      Unloading informations
                    </h3>

                    <h3 style={{ marginBottom: "0", marginTop: 1 }}>
                      Delivery : <strong>{deliveryType}</strong>
                    </h3>
                  </div>
                  <div className="load_body">
                    <div className="loadingBox_flex">
                      <h3>
                        Reference : <strong>{deliveryRefernce}</strong>
                      </h3>
                      <h3>
                        Date :{" "}
                        <strong>
                          {moment(deliveryDate).format("DD.MM.YYYY")}
                        </strong>
                      </h3>
                      <h3>
                        Time : <strong>{deliveryTime}</strong>
                      </h3>
                    </div>
                    <h3>
                      <strong>
                        {deliveryLocation === true
                          ? "Appointment needed"
                          : "Appointment not required"}
                      </strong>
                    </h3>

                    <h3 className="mt-3">Address</h3>

                    <h3 className="loadingBox_address ">
                      <strong>{deliveryAddress}</strong>
                    </h3>

                    <div className="loadingBox_flex mt-3">
                      <h3>
                        Contact : <strong>{deliveryContactName}</strong>
                      </h3>
                    </div>
                    <div className="loadingBox_flex">
                      <h3>
                        Phone :{" "}
                        <strong>
                          {unLoadingCountryCode} {deliveryContactPhone}
                        </strong>
                      </h3>
                      <h3>
                        Email : <strong>{deliveryContactEmail}</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="matTable">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>Material informations</th>
                    {activeTab === "Refrigerated" && (
                      <th className="text-center">{"Temperature"}</th>
                    )}
                    <th className="text-center">{"Weight"}</th>
                    {activeTab === "Tanker" ? (
                      ""
                    ) : (
                      <th className="text-center">{"Nb of pallets"}</th>
                    )}
                    {activeTab === "Tanker" ? (
                      ""
                    ) : (
                      <th className="text-center">{"Size of pallets"}</th>
                    )}
                    {activeTab === "Tanker" ? (
                      ""
                    ) : (
                      <th className="text-center">{"Height"}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* General cargo */}
                  {activeTab === "General cargo" &&
                    materials &&
                    materials.map((item, index) => {
                      return (
                        <tr className="oddtr" key={index}>
                          <td>{item.materialName}</td>
                          <td className="text-center">
                            {item.totalWeightOfPallets} kg
                          </td>
                          <td className="text-center">
                            {item.numberOfPallets}
                          </td>
                          <td className="text-center">{item.sizeOfPallets}</td>
                          <td className="text-center">
                            {item.heightOfPallets}
                          </td>
                        </tr>
                      );
                    })}

                  {activeTab === "Tanker" &&
                    savedMaterials &&
                    savedMaterials.length > 0 &&
                    savedMaterials.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <strong>{item.materialName}</strong>
                              {console.log("urem", item)}
                              {item.ADR == "Yes" && (
                                <div style={{ marginTop: 5 }}>
                                  ADR : {item.adrReference} <br />
                                </div>
                              )}
                              <div>
                                {item.selectedOptions &&
                                  item.selectedOptions.join(", ")}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ verticalAlign: "middle" }}
                            >
                              {item.quantity} L
                            </td>
                          </tr>
                        </>
                      );
                    })}

                  {/* Refrigerated */}
                  {activeTab === "Refrigerated" &&
                    refrigeratedMaterials &&
                    refrigeratedMaterials.map((item, index) => {
                      console.log(
                        "refrigeratedMaterials on view",
                        refrigeratedMaterials
                      );
                      {
                        console.log("item msg", item);
                      }
                      return (
                        <tr className="oddtr" key={index}>
                          <td>
                            <strong>{item.materialName}</strong>
                            <br />
                            {item.msds === "Yes" ? "MSDS included" : ""}
                          </td>
                          {activeTab === "Refrigerated" && (
                            <td className="text-center">
                              {item.temprature} °C
                            </td>
                          )}

                          <td className="text-center">
                            {item.totalWeightOfPallets} kg
                          </td>
                          <td className="text-center">
                            {item.numberOfPallets}
                          </td>
                          <td className="text-center">{item.sizeOfPallets}</td>
                          <td className="text-center">
                            {item.heightOfPallets}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <div className="braedCrumbNoticon mb-2">
              <h4>Other informations</h4>
            </div>

            <Form.Group className="mb-3">
              <Form.Control
                style={{ borderRadius: 2 }}
                as="textarea"
                rows={3}
                value={otherInformation}
                // onChange={(text) => setOtherInformation(text.target.value)}
              />
            </Form.Group>
            {/* {userDeatildata.profile !== 'User' &&( */}
            <>
              {selectedCarriers && selectedCarriers.length === 0 ? (
                ""
              ) : (
                <div className="braedCrumbNoticon mb-2">
                  <h4>Carriers selection</h4>
                </div>
              )}

              <div className="carrieBoxMain carrieBoxMainFlex">
                {selectedCarriers &&
                  selectedCarriers.map((item, index) => {
                    var selectCarrierName =
                      item && userDeatildata.profile == "User"
                        ? item.additionalData.accountNumber
                        : item.additionalData.companyName;

                    var checkRating = item && item.additionalData.Totalrating;
                   
                    const average_rating =
                      item.carrier_ratings > 0
                        ? item.carrier_ratings.toFixed(1)
                        : 0;
                    const complete_missions = item.complete_missions;
                    const missions_txt =
                      item.complete_missions > 1 ? "missions" : "mission";

                    return (
                      <div className="carrieBox" key={index}>
                        <div className="iconBox">
                          <Image
                            src={TruckWhiteIcon}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="car_right">
                          <h5>{selectCarrierName}</h5>

                          <div className="startFlex">
                            {/* {(() => {
                              const arr = [];
                              for (let i = 0; i < 5; i++) {
                                arr.push(
                                  <>
                                    {i < Math.round(checkRating) ? (
                                      <FaStar color={"#F7CE6C"} size={16} />
                                    ) : (
                                      <FaRegStar color="#F7CE6C" size={16} />
                                    )}
                                  </>
                                );
                              }
                              return arr;
                            })()} */}
                             <ReactStarsRating
                              className={"Star_rating"}
                              isHalf={true}
                              size={16}
                              starGap={0}
                              isEdit={false}
                              value={average_rating}
                            />
                            <span>{average_rating}/5</span>
                          </div>
                          <h5>{complete_missions} {missions_txt}</h5>
                          {/* Dynamic */}
                          {/* <div className="startFlex">
                            <ReactStarsRating
                              className={"Star_rating"}
                              isHalf={true}
                              size={16}
                              starGap={0}
                              isEdit={false}
                              value={average_rating}
                            />
                            <span>{average_rating}/5</span>
                          </div>
                          <h5>
                            {complete_missions} {missions_txt}
                          </h5> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
            {/* )} */}
            <div className="modal_footer">
              <Button
                className="btn-primaryAll btnPrimaryoutline me-3"
                onClick={props.onHide}
              >
                Back
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewQuoteDetails;
