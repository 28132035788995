import React from "react";
import { Form } from "react-bootstrap";

const EditFieldTextInput = (props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>
        {props.label}
        <span className="requiresLable">{props.reqstart}</span>
      </Form.Label>
      <Form.Control
        onClick={props.onClick}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      />
    </Form.Group>
  );
};

export default EditFieldTextInput;
