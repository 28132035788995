import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginimg from "../images/login_img2.jpg";
import { user_Login } from "../services/UserApiServices/UserApiCalls";
import { ToastContainer, toast } from "react-toastify";
import * as SERVICE from "../services/index";

const ClientLogin = () => {
  const navigate = useNavigate();
  localStorage.setItem("access", JSON.stringify("clientAccess"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setemailError] = useState(0);
  const [passwordError, setpasswordError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const onProfileValidation = async () => {
    let errorCount = 0;

    let emailError = SERVICE.validateEmail(email);
    if (emailError !== undefined) {
      errorCount++;
      let msg =
        emailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (!password) {
      errorCount++;
      let msg = "Please enter your password.";
      setpasswordError(msg);
    } else {
      setpasswordError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const onSubmit = () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("check if");
      console.log("errorCount", errorCount);
      return;
    } else {
      userLogin();
      console.log("call api");
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, password]);

  const userLogin = async () => {
    const payload = {
      userEmail: email,
      userPassword: password,
      type: "User",
    };
    console.log("user login payload", payload);
    setIsLoading(true);
    await user_Login(payload)
      .then(async (res) => {
        console.log("res", res);
        if (res.status === true) {
          await localStorage.setItem("userDetail", JSON.stringify(res.data));
          await localStorage.setItem("token", JSON.stringify(res.token));
          toast("User Login Successfully");

          setTimeout(() => {
            navigate(`/quotes`);
          }, 1500);
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast("Please check you email and password");
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      <section className="loginmain_div">
        <Container fluid>
          <Row>
            <Col md={12} lg={7} className="align-self-center">
              <div className="loginform_div py_200">
                <h4 className="login_head">Login</h4>
                <Form>
                  <Form.Group className="form_group" controlId="formGroupEmail">
                    <Form.Label>Email or account number</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter an email or account number"
                      defaultValue=""
                      onChange={(text) => setEmail(text.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") onSubmit();
                      }}
                    />
                    {emailError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {emailError}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    className="form_group"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter a password"
                      defaultValue=""
                      onChange={(text) => setPassword(text.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") onSubmit();
                      }}
                    />
                    {passwordError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {passwordError}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="form_group text-end">
                    <Link
                      className="forgotpassword_link"
                      to={`/forgotPassword`}
                    >
                      Forgot password ?
                    </Link>
                  </Form.Group>
                  <div className="text-center">
                    <Button
                      as={Link}
                      // to="/carrier_quotes"
                      variant="primary"
                      className="login_btn mt-2"
                      onClick={() => onSubmit()}
                    >
                      Connexion
                    </Button>
                  </div>
                  <div className="loginbottom_div">
                    <p>
                      You don't have an account ?{" "}
                      <Link to={"/userSignup"}>Register here</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={5} className="px-0">
              <Image className="loginimg" src={loginimg} alt="" />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ClientLogin;
