import React, { useState, useEffect } from "react"
import SideBar from "../inc/SideBar"
import NavBar from "../inc/NavBar"
import FilterSidebar from "../inc/FilterSidebar"
import { Container } from "react-bootstrap"

const Settings = () => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar")
    } else {
      document.body.classList.remove("filter_sidebar")
    }
  }, [toggle])

  const handleToggle = () => {
    setToggle(!toggle)
  }

  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <NavBar />
          <FilterSidebar close={handleToggle} />

          <Container fluid className="pt-100">
            <div className="quotes_div">Settings</div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Settings
