import React from "react";
import { Form } from "react-bootstrap";

const TextInputAreaWithLabel = (props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
      />
    </Form.Group>
  );
};

export default TextInputAreaWithLabel;
