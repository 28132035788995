import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import loginimg from "../images/login_img2.jpg";
import FlagIcon from "../images/aus.svg";
import FlagIcon1 from "../images/french.svg";
import FlagIcon3 from "../images/usa_flag.svg";
import { BiSolidInfoCircle } from "react-icons/bi";
import Select from "react-select";
import {
  country_PhoneNumberCode,
  getSignup,
} from "../../component/services/ApiCalls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as SERVICE from "../../component/services/index";
import { user_Signup } from "../../component/services/UserApiServices/UserApiCalls";
import Loader from "../../common/component/Loader";
import { AppStoreContext } from "../../store/AppStore";
import consoleService from "../../utility/services/consoleService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const UserSignup = ({ user_type }) => {
  const navigate = useNavigate();
  const { setAccess, setUserDetails } = useContext(AppStoreContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [selectOption, setSelectedOption] = useState("+33");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [emailError, setemailError] = useState(0);
  const [passwordError, setpasswordError] = useState(0);
  const [firstNameError, setFirstNameError] = useState(0);
  const [lastNameError, setLastNameError] = useState(0);
  const [companyError, setCompanyError] = useState(0);
  const [phoneNumberError, setPhoneNumberError] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const onProfileValidation = async () => {
    let errorCount = 0;

    let emailError = SERVICE.validateEmail(email);
    if (emailError !== undefined) {
      errorCount++;
      let msg =
        emailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setemailError(msg);
    } else {
      setemailError(null);
    }
    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your password.";
      setpasswordError(msg);
    } else if (password) {
      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (!password.match(strongRegex)) {
        errorCount++;
        let msg1 =
          "Password length should be minimum 8 characters and contain atleast 1 number, 1 uppercase, 1 lowercase and 1 special character from these(@#$!%*^?&).";
        setpasswordError(msg1);
      } else {
        setpasswordError(null);
      }
    } else {
      setpasswordError(null);
    }

    if (!firstName) {
      errorCount++;
      let msg = "Please enter your first name.";
      setFirstNameError(msg);
    } else {
      setFirstNameError(null);
    }

    if (!lastName) {
      errorCount++;
      let msg = "Please enter your last name.";
      setLastNameError(msg);
    } else {
      setLastNameError(null);
    }

    if (!company) {
      errorCount++;
      let msg = "Please enter your company name.";
      setCompanyError(msg);
    } else {
      setCompanyError(null);
    }

    if (!phoneNumber) {
      errorCount++;
      let msg = "Please enter your phone name.";
      setPhoneNumberError(msg);
    } else {
      setPhoneNumberError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("check if");
      console.log("errorCount", errorCount);
      return;
    } else {
      fetchData();
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const payload = {
        profile: user_type,
        firstName: firstName,
        lastName: lastName,
        company: company,
        phone: phoneNumber,
        email: email.trim().toLocaleLowerCase(),
        password: password,
        // CountryCode: selectOption.value,
        CountryCode: selectedCountryCode,
      };

      console.log("payload--->", payload);

      const submit_res = await user_Signup(payload);
      console.log("submit_res  =", submit_res);
      if (submit_res.status) {
        const isRoleUser = user_type == "User";
        const access = isRoleUser ? "userAccess" : "carrierAccess";
        localStorage.setItem("userDetail", JSON.stringify(submit_res.data));
        localStorage.setItem("token", JSON.stringify(submit_res.token));
        localStorage.setItem("access", JSON.stringify(access));
        setAccess(access);
        setUserDetails(submit_res.data);
        setIsLoading(false);
        setTimeout(() => {
          toast("SignUp Successfully");
        }, 2000);

        navigate(`/account`, { replace: true });
      } else {
        setIsLoading(false);
        toast(submit_res.msg);
      }
    } catch (error) {
      console.error("Error in function:", error);
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, password, phoneNumber, firstName, lastName, company]);

  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  return (
    <React.Fragment>
      <section className="loginmain_div">
        <Container fluid>
          <Row>
            <Col md={12} lg={7} className="align-self-center">
              <div className="loginform_div py_200">
                <h4 className="login_head">Create an account</h4>
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupProfile"
                      >
                        <Form.Label>
                          Profile <BiSolidInfoCircle />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Profile"
                          defaultValue="User"
                          disabled
                          value={user_type}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupFirstname"
                      >
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex : Michel"
                          defaultValue=""
                          onChange={(text) => setFirstName(text.target.value)}
                        />
                      </Form.Group>
                      {firstNameError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {firstNameError}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupLastname"
                      >
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex: Dupont"
                          defaultValue=""
                          onChange={(text) => setLastName(text.target.value)}
                        />
                      </Form.Group>
                      {lastNameError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {lastNameError}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupCompany"
                      >
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex : Duroc"
                          defaultValue=""
                          onChange={(text) => setCompany(text.target.value)}
                        />
                      </Form.Group>
                      {companyError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {companyError}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupPhone"
                      >
                        <Form.Label>Phone</Form.Label>
                        {/* <div className="phonecode_div">
                          <Select
                            defaultValue={options[0]}
                            isSearchable={false}
                            onChange={setSelectedOption}
                            styles={customStyles}
                            options={options}
                            className="country_code"
                          /> */}
                        <div className="counCode countFLEX">
                          <Form.Select
                            size="lg"
                            value={selectedCountryCode}
                            onChange={handleLoadingCountryCode}
                            style={{ marginTop: "-1rem" }}
                          >
                            {phoneNumberCode.map((option, index) => (
                              <option key={index} value={option.code}>
                                {option && option.abbreviation} ({option.code})
                              </option>
                            ))}
                          </Form.Select>

                          <Form.Control
                            type="text"
                            placeholder="Ex : 06 58 45 82 36"
                            defaultValue=""
                            onChange={(text) =>
                              setPhoneNumber(text.target.value)
                            }
                          />
                        </div>
                      </Form.Group>
                      {phoneNumberError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {phoneNumberError}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupEmail"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Ex : michel.dupont@mail.com"
                          defaultValue=""
                          onChange={(text) => setEmail(text.target.value)}
                        />
                      </Form.Group>
                      {emailError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {emailError}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form_group"
                        controlId="formGroupPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <div
                          className="password-input-container"
                          style={{ position: "relative" }}
                        >
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter a password"
                            defaultValue=""
                            onChange={(text) => setPassword(text.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") onSubmit();
                            }}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </span>
                        </div>
                        <p className="password_text">
                          <span>• 8 characters</span> <span>• 1 majuscule</span>{" "}
                          <span>• 1 minuscule</span> <span>• 1 special</span>
                        </p>
                      </Form.Group>
                      {passwordError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {passwordError}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={12}>
                      <div className="text-center">
                        <Button
                          variant="primary"
                          className="login_btn mt-2"
                          onClick={() => {
                            onSubmit();
                          }}
                        >
                          {isLoading ? <Loader /> : "Create account"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="loginbottom_div">
                    <p>
                      Already have an account ?{" "}
                      <Link to={`/user_login`}>Login here</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={5} className="px-0">
              <Image className="loginimg" src={loginimg} alt="" />
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </React.Fragment>
  );
};

export default UserSignup;
