import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginimg from "../images/login_img2.jpg";
import { getLogin, login_Staff } from "../services/ApiCalls";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppStoreContext } from "../../store/AppStore";
import Loader from "../../common/component/Loader";
import consoleService from "../../utility/services/consoleService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


const Login = () => {
  const { setUserDetails } = useContext(AppStoreContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setemailError] = useState(0);
  const [passwordError, setpasswordError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const endpoint = location.pathname;

  localStorage.setItem("access", JSON.stringify("adminAccess"));

  const onProfileValidation = async () => {
    let errorCount = 0;

    // let emailError = SERVICE.validateEmail(email);
    // if (emailError !== undefined) {
    //   errorCount++;
    //   let msg =
    //     emailError === "required"
    //       ? "Please enter your email address."
    //       : "Please enter a valid email address.";
    //   setemailError(msg);
    // } else {
    //   setemailError(null);
    // }

    if (!email) {
      errorCount++;
      let msg = "Please enter your email address or account number";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (!password) {
      errorCount++;
      let msg = "Please enter your password.";
      setpasswordError(msg);
    } else {
      setpasswordError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("check if");
      console.log("errorCount", errorCount);
      return;
    } else {
      fetchLoginData();
      console.log("call api");
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, password]);

  const fetchLoginData = async () => {
    const payload = {
      email: email,
      password: password,
    };

    setIsLoading(true);
    const Res = await getLogin(payload);
    if (Res.status === true) {
      localStorage.setItem("userDetail", JSON.stringify(Res.data));
      localStorage.setItem("token", JSON.stringify(Res.token));
      localStorage.setItem("access", JSON.stringify("adminAccess"));
      setUserDetails(Res.data);

      setTimeout(() => {
        toast("Login Successfully");
      }, 2000);
      navigate(`/account`, { replace: true });
      setIsLoading(false);
    } else {
      toast(Res.msg);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <section className="loginmain_div">
        <Container fluid>
          <Row>
            <Col md={12} lg={6} className="align-self-center">
              <div className="loginform_div py_200 px-4">
                <h4 className="login_head">Login</h4>
                <Form>
                  <Form.Group
                    className="form_group mb-3"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>Email or account number</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email or account number"
                      defaultValue=""
                      onChange={(text) => setEmail(text.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") onSubmit();
                      }}
                    />
                    {emailError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {emailError}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    className="form_group"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <div className="password-input-container" style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter a password"
                      defaultValue=""
                      onChange={(text) => setPassword(text.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") onSubmit();
                      }}
                    />
                     <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </span>
                    </div>
                    {passwordError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {passwordError}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="form_group text-end">
                    <Link
                      className="forgotpassword_link"
                      to={`/admin_forgotPassword`}
                    >
                      Forgot password ?
                    </Link>
                  </Form.Group>
                  <div className="text-center">
                    <Button
                      onClick={onSubmit}
                      variant="primary"
                      className="login_btn mt-2"
                    >
                      {isLoading ? <Loader /> : "Connexion"}
                    </Button>
                  </div>
                  <div className="loginbottom_div"></div>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={6} className="px-0">
              <Image className="loginimg" src={loginimg} alt="" />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Login;
