import React from "react";
import { Form } from "react-bootstrap";

const TextInputWithLabel = (props) => {
  return (
    <Form.Group className="mb-3" style={props.inputStyle}>
      {props.label && (
        <Form.Label>
          {props.label}
          <span className="requiresLable">{props.reqstart}</span>
        </Form.Label>
      )}
      <Form.Control
        onClick={props.onClick}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        readOnly={props.readOnly}
      />
    </Form.Group>
  );
};

export default TextInputWithLabel;
