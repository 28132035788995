import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import loginimg from "../images/login_img2.jpg";
import {
  getAdminDetail,
  getLogin,
  getResetPassword,
  staff_ResetPassword,
  confirm_reset_password_otp,
} from "../services/ApiCalls";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import consoleService from "../../utility/services/consoleService";
import {
  CardTitle,
  CardText,
  Form,
  Button,
  Label,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Loader from "../../common/component/Loader";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const defaultValues = {
    new_password: "",
    confirm_password: "",
  };

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    if (
      Object.values(data).every((field) => field.toString().trim().length > 0)
    ) {
      try {
        const NewPass = data.new_password;
        const ConfirmPass = data.confirm_password;
        const strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        );
        if (!NewPass.match(strongRegex)) {
          setError("new_password", {
            type: "manual",
            message:
              "Password length should be minimum 8 characters and contain atleast 1 number, 1 uppercase, 1 lowercase and 1 special character from these(@#$!%*^?&).",
          });
          return;
        }
        if (NewPass.trim() == ConfirmPass.trim()) {
          setLoading(true);
          check_otp_auth(NewPass);
        } else {
          setLoading(false);
          setError("confirm_password", {
            type: "manual",
            message: "Password doesn't match with each other.",
          });
        }
      } catch (err) {
        setLoading(false);
        consoleService("error in submit fun = ", err);
      }
    } else {
      for (const key in data) {
        let field_name;
        switch (key) {
          case "new_password":
            field_name = "New password";
            break;
          default:
            field_name = "Confirm password";
        }
        if (data[key].length === 0) {
          setError(key, {
            type: "manual",
            message: `${field_name} required.`,
          });
        }
      }
      setLoading(false);
    }
  };

  async function check_otp_auth(NewPass) {
    const queryString = window.location.href;
    const searchParams = new URLSearchParams(queryString.split("?")[1]);
    const otp = searchParams.get("auth");
    const reset_id = searchParams.get("reset_id");
    const user_code = searchParams.get("user_code");
    const formdata = { reset_id, otp, user_code, password: NewPass };

    const res = await confirm_reset_password_otp(formdata);

    if (res.status === 1) {
      // 0 for other
      // 1 for admin
      // 2 staff
      let navigate_link = "";
      switch (user_code) {
        case "0":
          navigate_link = "/user_login";
          break;
        case "1":
          navigate_link = "/login";
          break;
        case "2":
          navigate_link = "/staffLogin";
      }

      setLoading(false);
      navigate(navigate_link, { replace: true });
      setTimeout(() => {
        toast("Password reset successfully.");
      }, 200);
    } else {
      toast.error("Authentication Failed.");
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <section className="loginmain_div">
        <Container fluid>
          <Row>
            <Col md={12} lg={7} className="align-self-center">
              <div className="loginform_div py_200">
                <h4 className="login_head">Reset Password</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className="form_group" controlId="formGroupEmail">
                    <Label className="form-label" for="new-password">
                      Enter New Password
                    </Label>
                    <Controller
                      id="new_password"
                      name="new_password"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="password"
                          placeholder="Enter New Password"
                          invalid={errors.new_password && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.new_password && (
                      <FormFeedback className="validation_txt">
                        {errors.new_password.message}
                      </FormFeedback>
                    )}
                  </FormGroup>

                  <FormGroup
                    className="form_group"
                    controlId="formGroupEmail"
                    style={{ marginTop: 20 }}
                  >
                    <Label className="form-label" for="new-password">
                      Enter Confirm Password
                    </Label>
                    <Controller
                      id="confirm_password"
                      name="confirm_password"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="password"
                          placeholder="Enter Confirm Password"
                          invalid={errors.confirm_password && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.confirm_password && (
                      <FormFeedback className="validation_txt">
                        {errors.confirm_password.message}
                      </FormFeedback>
                    )}
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className="login_btn mt-2"
                    >
                      {loading ? <Loader /> : "Connexion"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={5} className="px-0">
              <Image className="loginimg" src={loginimg} alt="" />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ResetPassword;
