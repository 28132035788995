export default (text, data) => {
  const isData = data != undefined;
  if (process.env.REACT_APP_DEVELOPEMENT_MODE == "true") {
    if (isData) {
      console.log(text, data);
    } else {
      console.log(text);
    }
  }
};
