import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Logo from "../images/logo.svg";
import UserIcon from "../images/usericon.svg";
import FlagIcon from "../images/aus.svg";
import FlagIcon1 from "../images/french.svg";
import Select from "react-select";
import const_config from "../../utility/config/const_config";
import consoleService from "../../utility/services/consoleService";

const options = [
  {
    value: "English",
    label: (
      <div>
        <Image src={FlagIcon} alt="country-image" /> English
      </div>
    ),
  },
  {
    value: "French",
    label: (
      <div>
        <Image src={FlagIcon1} alt="country-image" /> Français
      </div>
    ),
  },
];

const Header = () => {
  // const currentpath = window.location.hash.split("#")[1]
  //   ? window.location.hash.split("#")[1].toString().trim()
  //   : "";
  const currentpath = window.location.pathname;
  const Paths = const_config.show_header_footer_paths;
  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const [setSelectedOption] = useState(null);
  const isShowHeader = () => {
    const condition = Paths.includes(currentpath);
    return condition;
  };

  return (
    <>
      {isShowHeader() && (
        <Navbar collapseOnSelect expand="lg" id="home" className="bg-primary">
          <Container>
            <Navbar.Brand as={Link} to="/">
              <Image src={Logo} width="160" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto newNav">
                <Nav.Link as={Link} to="/user_login">
                  <Image src={UserIcon} width="20" /> Login
                </Nav.Link>
                <Select
                  defaultValue={options[0]}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Nav>
              <Nav className="ms-auto">
                <Nav.Link
                  className={url === "/" ? " active" : ""}
                  as={Link}
                  to="/"
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  className={url === "/solutions" ? " active" : ""}
                  as={Link}
                  to="/solutions"
                >
                  Solutions
                </Nav.Link>

                <Nav.Link
                  className={url === "/web_tracking" ? " active" : ""}
                  as={Link}
                  to="/web_tracking"
                >
                  Tracking
                </Nav.Link>

                <Nav.Link
                  className={url === "/contact" ? " active" : ""}
                  as={Link}
                  to="/contact"
                >
                  Contact
                </Nav.Link>

                <div className="btnBox">
                  <Button
                    as={Link}
                    to="/carriersignup"
                    variant="primary"
                    className="me-2 btnLight_Header"
                  >
                    Join as carrier
                  </Button>
                  <Button
                    as={Link}
                    className="btnPrimary_Header"
                    to="/usersignup"
                    variant="primary"
                  >
                    Start a quote
                  </Button>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default Header;
