import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: true,
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        boxHeight: 8,
        pointStyle: "circle",
        font: {
          size: 12,
          family: '"Poppins", sans-serif',
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            reverse: false,
          },
        },
      ],
    },
    title: {
      display: false,
      text: "Carrier rate",
      font: {
        family: '"Poppins", sans-serif',
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function LineChartOne({ ratingData }) {
  const extractAverageRatings = (ratingsArray) =>
    ratingsArray.map((item) => item.averageRating);

  const [chartData, setChartData] = useState({
    labels,
    datasets: [
      {
        label: "Client satisfaction",
        data: extractAverageRatings(ratingData.averageRatings),
        borderColor: "#5A6ACF",
        backgroundColor: "#5A6ACF",
      },
      {
        label: "OTIF",
        data: extractAverageRatings(ratingData.averageOTIFRatings),
        borderColor: "#EF5DA8",
        backgroundColor: "#EF5DA8",
      },
      {
        label: "Platform obligation",
        data: extractAverageRatings(
          ratingData.averagePlatformObligationRatings
        ),
        borderColor: "#2F80ED",
        backgroundColor: "#2F80ED",
      },
      {
        label: "Correction",
        data: extractAverageRatings(ratingData.averageCorrectionRatings),
        borderColor: "#27AE60",
        backgroundColor: "#27AE60",
      },
    ],
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("https://mottruck.com:3001/carrier-rating-graph");
  //       console.log('API Response:', response.data); // Log the API response

  //       const data = response.data.data;
  //       if (
  //         !data ||
  //         !Array.isArray(data.averageRatings) ||
  //         !Array.isArray(data.averageOTIFRatings) ||
  //         !Array.isArray(data.averagePlatformObligationRatings) ||
  //         !Array.isArray(data.averageCorrectionRatings)
  //       ) {
  //         throw new Error("Invalid data format");
  //       }

  //       const extractAverageRatings = (ratingsArray) => ratingsArray.map(item => item.averageRating);

  //       setChartData({
  //         labels,
  //         datasets: [
  //           {
  //             label: "Client satisfaction",
  //             data: extractAverageRatings(data.averageRatings),
  //             borderColor: "#5A6ACF",
  //             backgroundColor: "#5A6ACF"
  //           },
  //           {
  //             label: "OTIF",
  //             data: extractAverageRatings(data.averageOTIFRatings),
  //             borderColor: "#EF5DA8",
  //             backgroundColor: "#EF5DA8"
  //           },
  //           {
  //             label: "Platform obligation",
  //             data: extractAverageRatings(data.averagePlatformObligationRatings),
  //             borderColor: "#2F80ED",
  //             backgroundColor: "#2F80ED"
  //           },
  //           {
  //             label: "Correction",
  //             data: extractAverageRatings(data.averageCorrectionRatings),
  //             borderColor: "#27AE60",
  //             backgroundColor: "#27AE60"
  //           }
  //         ]
  //       });
  //     } catch (error) {
  //       console.error("Error fetching data", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return <Line options={options} height={60} data={chartData} />;
}
