import React from 'react';

const TextInputWithLabel = ({ name, placeholder, label, reqstart, value, onChange, error }) => (
  <div className="form-group">
    <label>{label} {reqstart && <span className="text-danger">{reqstart}</span>}</label>
    <input
      type="text"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`form-control ${error ? 'is-invalid' : ''}`}
    />
    {error && <div className="invalid-feedback">{error}</div>}
  </div>
);

export default TextInputWithLabel;
