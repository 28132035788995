import { useState, useEffect, useContext, useMemo } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  Form,
  Container,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import TruckIcon from "../images/truck.svg";
import TaskIcon from "../images/task.svg";
import TruckWhiteIcon from "../images/truckwhite.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import { FaStar, FaRegStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "../images/searchicon.svg";
import * as SERVICE from "../services/index";
import { add_Quote, staff_CarrierList } from "../services/ApiCalls";
import { QuotesDataContext } from "../services/QuotesData";
import ReactStarsRating from "react-awesome-stars-rating";
import { AppStoreContext } from "../../store/AppStore";

const NewQouteModalOneTwo = (props) => {
  const [staffCarrierData, setStaffCarrierData] = useState([]);

  const {
    activeTab,
    date,
    deliveryDate,
    materials,
    loadingLatLong,
    refrigeratedMaterials,
    savedMaterials
    } = useContext(QuotesDataContext);

    const { userDetail } = useContext(AppStoreContext);
    const userDeatildata = userDetail;
    const {
      _id,
      email,
      firstName,
      lastName,
      accountOnwerFirstName,
      accountOnwerLastName,
      profile,
    } = userDeatildata;
    const role = profile;
   

  useEffect(() => {
    if(props.show){
      fetchCarrierList();
    }
  }, [props]);

  const fetchCarrierList = () => {
    console.log("activeTab",activeTab)
    if(activeTab == 'General cargo'){
      var totalWeight = materials.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.totalWeightOfPallets);
      }, 0);
    }else if(activeTab == 'Refrigerated'){
      var totalWeight = refrigeratedMaterials.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.totalWeightOfPallets);
      }, 0);
    }else{
      var totalWeight = savedMaterials.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.quantity);
      }, 0);
    }
    console.log("totalWeight",totalWeight)
   
      
    const payload = {
      category:activeTab,
      start_date:date,
      end_date:deliveryDate,
      total_weight:totalWeight,
      latitude:loadingLatLong.lat,
      longitude:loadingLatLong.lng
    };
    staff_CarrierList(payload)
      .then((res) => {
        setStaffCarrierData(res.result.CarrierList);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleCarrierSelection = (item) => {
    const updatedSelectedCarriers = [...selectedCarriers];
    const updatedSelectedCarrierIds = [...selectedCarrierIds];
   
  
    if (
      updatedSelectedCarriers.some(
        (selectedItem) => selectedItem._id === item._id
      )
    ) {
      const indexToRemove = updatedSelectedCarriers.findIndex(
        (selectedItem) => selectedItem._id === item._id
      );
      updatedSelectedCarriers.splice(indexToRemove, 1);
      updatedSelectedCarrierIds.splice(indexToRemove, 1);
    } else if (updatedSelectedCarriers.length < 5) {
      updatedSelectedCarriers.push(item);
      updatedSelectedCarrierIds.push({ id: item._id, number_plate: item.number_plate });
    } else {
      toast("You can only select 5 carriers");
    }
    // console.log("updatedSelectedCarrierIds", updatedSelectedCarrierIds);
    setSelectedCarriers(updatedSelectedCarriers);
    setSelectedCarrierIds(updatedSelectedCarrierIds);
  };

  const {
    selectedCarriers,
    setSelectedCarriers,
    selectedCarrierIds,
    setSelectedCarrierIds,
  } = useContext(QuotesDataContext);
  const abe = selectedCarriers.length;

  const onSubmit = (props) => {
    if (selectedCarriers.length > 0) {
      props.onShowOtherModal();
    } else {
      toast("Please Choose Your Carrier");
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>New quote</h2>
        <BreadCrumbHeader
          image={TruckIcon}
          title={`CHOOSE YOUR CARRIER(S) - ${abe}/5 maximum`}
        />
        {staffCarrierData.length > 0 ? (  
         staffCarrierData.map((item, index) => {
          const average_rating =
            item.average_rating > 0 ? item.average_rating.toFixed(1) : 0;

          const complete_missions = item.complete_missions;
          const missions_txt =
            item.complete_missions > 1 ? "missions" : "mission";

          return (
            <div className="carrieBoxMain" key={index}>
              {["checkbox"].map((type) => {
                return (
                  <div key={`default-${index}`} className="textColor">
                    <Form.Check
                      type={type}
                      id={`default-${index}`}
                      checked={selectedCarriers.some(
                        (selectedItem) => selectedItem._id === item._id
                      )}
                      onChange={() => handleCarrierSelection(item)}
                      label={
                        <div className="carrieBox">
                          <div className="iconBox">
                            <Image
                              src={TruckWhiteIcon}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="car_right">
                          {/* <h5>
                            {item.accountNumber} ({item.number_plate}) 
                            {role === 'Staff' && `${item.accountOwnerFirstName} ${item.accountOwnerLastName}`}
                          </h5> */}
                          <h5>
                            {role === 'Staff' 
                              ? `${item.companyName} (${item.number_plate})` 
                              : `${item.accountNumber} (${item.number_plate})`
                            }
                          </h5>

                            <div className="startFlex">
                              <ReactStarsRating
                                className={"Star_rating"}
                                isHalf={true}
                                size={16}
                                starGap={0}
                                isEdit={false}
                                value={average_rating}
                              />

                              <span>{average_rating}/5</span>
                            </div>
                            <h5>
                              {complete_missions} {missions_txt}
                            </h5>
                          </div>
                        </div>
                      }
                    />
                  </div>
                );
              })}
              
            </div>
          );
        })
      ) : (
        <div className="nodatta">
                <p>No carrier availabile</p>
          </div>
      )}    
        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.close}
          >
            Back
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => onSubmit(props)}
          >
            Save carrier(s)
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default NewQouteModalOneTwo;
