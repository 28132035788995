import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ userquotes, staffquotes }) => {
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const userData = {
    label: "User",
    data: userquotes.map((quote) => quote.marginPrice?.$numberDecimal || 0),
    backgroundColor: "#4F5B92",
    borderColor: "rgba(0,0,0,0)",
    borderWidth: 2,
    barThickness: 12,
    maxBarThickness: 20,
    borderRadius: 5,
    yAxisID: "orders",
  };

  const staffData = {
    label: "Client",
    data: staffquotes.map((quote) => quote.marginPrice?.$numberDecimal || 0),
    backgroundColor: "#5A6ACF",
    borderColor: "rgba(0,0,0,0)",
    borderWidth: 2,
    barThickness: 12,
    maxBarThickness: 20,
    borderRadius: 5,
    yAxisID: "orders",
  };

  const data = {
    labels,
    datasets: [userData, staffData],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 8,
          pointStyle: "circle",
          font: {
            size: 12,
            family: '"Poppins", sans-serif',
          },
        },
      },
      title: {
        display: true,
        align: "center",
        text: "Net profit",
        color: "#4F5B92",
        font: {
          size: 18,
          family: "Poppins",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      yAxes: [
        {
          id: "orders",
          position: "left",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Orders",
          },
        },
        {
          id: "margin",
          position: "right",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Margin Price",
          },
        },
      ],
      xAxes: [
        {
          maxBarThickness: 40,
          barPercentage: 0.8,
          categoryPercentage: 0.6,
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Bar options={options} height="300px" data={data} />
    </React.Fragment>
  );
};

export default BarChart;
