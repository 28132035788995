import React, { useEffect, useState } from "react";
import { Accordion, Button, Image } from "react-bootstrap";
import FilterIcon from "../images/filter.svg";
import ResetIcon from "../images/rotate-left.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import LocationIcon from "../images/location.svg";
import ShipIcon from "../images/ship.svg";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import { FaRegTimesCircle } from "react-icons/fa";
import const_config from "../../utility/config/const_config";

const FilterSidebar = ({ close, input_permissoins, handleFilterFun }) => {
  const inputPermissoins = input_permissoins ? input_permissoins : [];
  const input_key_permissions = const_config.filter_permission_keys;

  const [filterObj, setFilterObj] = useState({});
  const get_vehicle_category_class_name = (value) => {
    const condition = filterObj.vehicle_category == value.toLowerCase();
    if (condition) {
      return "tanker_selection_opt_bg_color";
    } else {
      return "";
    }
  };

  useEffect(() => {}, [filterObj]);

  const set_filter_obj = (key, item) => {
    const oldObject = filterObj;
    oldObject[key] = item.toLowerCase();
    setFilterObj({ ...oldObject });
  };

  const sumbit_fun = () => {
    handleFilterFun(filterObj);
    close();
  };

  const reset = () => {
    handleFilterFun({});
    setFilterObj({});
    close();
  };

  const check_permission = (input) => {
    if (inputPermissoins.includes(input)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="overlay_back"></div>
      <aside className="sidebar">
        <FaRegTimesCircle
          onClick={() => close()}
          size={25}
          className="crossIcon"
          color="#E10404"
        />

        <div className="siderfilter">
          <div
            onClick={() => {
              sumbit_fun();
            }}
          >
            <Image src={FilterIcon} alt="" /> <strong>Filters</strong>
          </div>
          <div
            onClick={() => {
              reset();
            }}
          >
            <Image src={ResetIcon} alt="" /> Reset
          </div>
        </div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <BreadCrumbHeader image={ShipIcon} title="VEHICLE CATEGORY" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="acc_cat" value={"no"}>
                <Button
                  onClick={() => {
                    set_filter_obj("vehicle_category", "General cargo");
                  }}
                  className={get_vehicle_category_class_name("General cargo")}
                >
                  General cargo
                </Button>
                <Button
                  onClick={() => {
                    set_filter_obj("vehicle_category", "Tanker");
                  }}
                  className={get_vehicle_category_class_name("Tanker")}
                >
                  Tanker
                </Button>
                <Button
                  onClick={() => {
                    set_filter_obj("vehicle_category", "Refrigerated");
                  }}
                  className={get_vehicle_category_class_name("Refrigerated")}
                >
                  Refrigerated
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <BreadCrumbHeader
                image={LocationIcon}
                title="LOADING INFORMATIONS"
              />
            </Accordion.Header>
            <Accordion.Body>
              <TextInputWithLabel
                label="City"
                type="text"
                placeholder="Enter a city"
                value={filterObj.loading_city ? filterObj.loading_city : ""}
                onChange={(e) => {
                  set_filter_obj("loading_city", e.target.value);
                }}
              />

              <TextInputWithLabel
                label="Date"
                type="date"
                placeholder="Enter Date"
                value={filterObj.loading_date ? filterObj.loading_date : ""}
                onChange={(e) => {
                  set_filter_obj("loading_date", e.target.value);
                }}
              />

              <TextInputWithLabel
                label="Hour"
                type="time"
                placeholder="Enter time"
                value={filterObj.loading_time ? filterObj.loading_time : ""}
                onChange={(e) => {
                  set_filter_obj("loading_time", e.target.value);
                }}
              />

              {check_permission(input_key_permissions.loading_contact_name) && (
                <TextInputWithLabel
                  label="Contact name"
                  type="text"
                  placeholder="Enter Contact name"
                  value={
                    filterObj.loading_contact_name
                      ? filterObj.loading_contact_name
                      : ""
                  }
                  onChange={(e) => {
                    set_filter_obj("loading_contact_name", e.target.value);
                  }}
                />
              )}

              {check_permission(
                input_key_permissions.loading_contact_phone
              ) && (
                <TextInputWithLabel
                  label="Contact phone"
                  type="text"
                  placeholder="Enter Contact phone"
                  value={
                    filterObj.loading_contact_phone
                      ? filterObj.loading_contact_phone
                      : ""
                  }
                  onChange={(e) => {
                    set_filter_obj("loading_contact_phone", e.target.value);
                  }}
                />
              )}

              {check_permission(
                input_key_permissions.loading_contact_email
              ) && (
                <TextInputWithLabel
                  label="Contact email"
                  type="email"
                  placeholder="Enter Contact email"
                  value={
                    filterObj.loading_contact_email
                      ? filterObj.loading_contact_email
                      : ""
                  }
                  onChange={(e) => {
                    set_filter_obj("loading_contact_email", e.target.value);
                  }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <BreadCrumbHeader
                image={LocationIcon}
                title="DELIVERY INFORMATIONS"
              />
            </Accordion.Header>
            <Accordion.Body>
              <TextInputWithLabel
                label="City"
                type="text"
                placeholder="Enter a city"
                value={filterObj.delivery_city ? filterObj.delivery_city : ""}
                onChange={(e) => {
                  set_filter_obj("delivery_city", e.target.value);
                }}
              />

              <TextInputWithLabel
                label="Date"
                type="date"
                placeholder="Enter Date"
                value={filterObj.delivery_date ? filterObj.delivery_date : ""}
                onChange={(e) => {
                  set_filter_obj("delivery_date", e.target.value);
                }}
              />

              <TextInputWithLabel
                label="Hour"
                type="time"
                placeholder="Enter time"
                value={filterObj.delivery_time ? filterObj.delivery_time : ""}
                onChange={(e) => {
                  set_filter_obj("delivery_time", e.target.value);
                }}
              />

              {check_permission(
                input_key_permissions.delivery_contact_name
              ) && (
                <TextInputWithLabel
                  label="Contact name"
                  type="text"
                  placeholder="Enter Contact name"
                  value={
                    filterObj.delivery_contact_name
                      ? filterObj.delivery_contact_name
                      : ""
                  }
                  onChange={(e) => {
                    set_filter_obj("delivery_contact_name", e.target.value);
                  }}
                />
              )}

              {check_permission(
                input_key_permissions.delivery_contact_phone
              ) && (
                <TextInputWithLabel
                  label="Contact phone"
                  type="text"
                  placeholder="Enter Contact phone"
                  value={
                    filterObj.delivery_contact_phone
                      ? filterObj.delivery_contact_phone
                      : ""
                  }
                  onChange={(e) => {
                    set_filter_obj("delivery_contact_phone", e.target.value);
                  }}
                />
              )}

              {check_permission(
                input_key_permissions.delivery_contact_email
              ) && (
                <TextInputWithLabel
                  label="Contact email"
                  type="email"
                  placeholder="Enter Contact email"
                  value={
                    filterObj.delivery_contact_email
                      ? filterObj.delivery_contact_email
                      : ""
                  }
                  onChange={(e) => {
                    set_filter_obj("delivery_contact_email", e.target.value);
                  }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </aside>
    </>
  );
};

export default FilterSidebar;
