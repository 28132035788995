import React from "react";
import { Image } from "react-bootstrap";

const BreadCrumbHeader = (props) => {
  return (
    <div className="braedCrumb">
      <Image className="tIcon" src={props.image} alt="" />
      <h3>{props.title}</h3>
    </div>
  );
};

export default BreadCrumbHeader;
