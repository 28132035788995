import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import loginimg from "../images/login_img2.jpg";
import { send_link_for_reset_pass } from "../services/ApiCalls";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_LINK } from "../services/env";
import consoleService from "../../utility/services/consoleService";
import Loader from "../../common/component/Loader";

const ForgotPassword = ({ user_role }) => {
  const [email, setEmail] = useState("");

  const [emailError, setemailError] = useState(0);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const onProfileValidation = async () => {
    let errorCount = 0;

    let emailError = SERVICE.validateEmail(email);
    if (emailError !== undefined) {
      errorCount++;
      let msg =
        emailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const onSubmit = () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("errorCount", errorCount);
      return;
    } else {
      send_link_fun();
    }
  };

  const send_link_fun = async () => {
    try {
      const payload = {
        email: email,
        link: `${URL_LINK}resetPassword`,
        user_role,
      };

      setLoading(true);

      const Res = await send_link_for_reset_pass(payload);

      if (Res.status === 1) {
        toast(`We've just sent an email to the address you provided. Please check
        your inbox for further instructions. The email contains important
        information to help you proceed with the next steps.`);
        setLoading(false);
      } else {
        toast.error(Res.msg);
        setLoading(false);
      }
    } catch (err) {
      consoleService("error in send_link_fun = ", err);
    }
  };

  return (
    <React.Fragment>
      <section className="loginmain_div">
        <Container fluid>
          <Row>
            <Col md={12} lg={7} className="align-self-center">
              <div className="loginform_div py_200">
                <h4 className="login_head">Forgot Password</h4>
                <Form>
                  <Form.Group className="form_group" controlId="formGroupEmail">
                    <Form.Label>Enter An Register Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter an email"
                      defaultValue=""
                      onChange={(text) => setEmail(text.target.value)}
                    />
                    {emailError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {emailError}
                      </div>
                    ) : null}
                  </Form.Group>

                  <div className="text-center">
                    <Button
                      as={Link}
                      // to={`/resetPassword/:id`}
                      onClick={onSubmit}
                      variant="primary"
                      className="login_btn mt-2"
                    >
                      {loading ? <Loader /> : "Connexion"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={5} className="px-0">
              <Image className="loginimg" src={loginimg} alt="" />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ForgotPassword;
