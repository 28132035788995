import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import SolutionsImg from "../images/solutions.jpg";
import ClientImg from "../images/client.jpg";
import UserBgImg from "../images/userbg.jpg";
import SolutionsImg1 from "../images/solutionsimg1.png";
import SolutionsImg2 from "../images/solutionsimg2.jpg";
import SolutionsImg3 from "../images/solutionsimg3.jpg";
import SolutionsImg4 from "../images/solutionsimg4.png";
import LikeSvg from "../images/like.svg";
import RoutingImg from "../images/routing.svg";
import TimerImg from "../images/timer.svg";
import GlobalImg from "../images/global.svg";
import BookImg from "../images/book.svg";
import StatusUp from "../images/status-up.svg";
import ClipboardImg from "../images/clipboard-text.svg";
import { Link } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import N_Img1 from "../images/nimg1.jpg";
import N_Img2 from "../images/nimg2.jpg";
import N_Img3 from "../images/nimg3.jpg";

const Solutions = () => {
  return (
    <React.Fragment>
      <section
        className="solution_banner"
        style={{ backgroundImage: `url(${SolutionsImg})` }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h1>
                MoTTruck is your
                <br />
                trusted partner
              </h1>
              <p className="mb-0">
                for all your FTL and LTL Road shipments across europe
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="shipper">
        <Container>
          <p>
            Our users benefit from our comprehensive, intuitive platform to
            simplify the organization and tracking of shipment. We connect our
            users to our solid network of carriers. We enable you to optimize
            your time and resources thanks to our tool, designed to guarantee
            harmonious information for different stake holders.
          </p>
          <Row className="justify-content-center">
            <Col md={8}>
              <h2>OUR SOLUTIONS FOR SHIPPERS</h2>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#section2").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                    className="clientBox"
                    style={{ backgroundImage: `url(${N_Img1})` }}
                  >
                    <div>
                      <h3>AS A</h3>
                      <h4>CLIENT</h4>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#section3").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                    className="clientBox"
                    style={{ backgroundImage: `url(${N_Img2})` }}
                  >
                    <div>
                      <h3>AS A</h3>
                      <h4>USER</h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="solutionsmain_div solutionsmain_Pad" id="section2">
        <Container>
          <Row>
            <Col md={6}>
              <div className="solution_img">
                <Image src={SolutionsImg1} alt="" />
              </div>
            </Col>
            <Col md={6}>
              <div className="solution_content">
                <h4 className="solution_head">Client Option</h4>
                <h3>
                  Optimize your logistics by outsourcing your transport
                  management.
                </h3>
                <p>
                  Our transport planner’s experts will organize your shipments
                  with complete confidence.
                </p>
                <p>
                  Our platform connects you with competent carriers, allowing
                  you to concentrate on your core business.
                </p>
                <p>
                  Be at the forefront of transportation management: take
                  advantage of our platorm for complete visibility and
                  simplified management, all in one place.
                </p>

                <Button
                  as={Link}
                  to="/contact"
                  variant="primary"
                  className="btn-primaryAll btnPrimary mt-2"
                >
                  Ask for a demo <FiArrowRight />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="solutionsmain_div client_div" id="section3">
        <section className="valueMargin">
          <div className="valuebox">
            <h2>OUR ADDED VALUES</h2>
            <div className="valuebox__items">
              <Image src={LikeSvg} alt="" />
              <p>Benefit from the best rates on the market</p>
            </div>
            <div className="valuebox__items">
              <Image src={RoutingImg} alt="" />
              <p>Track your shipments easily</p>
            </div>
            <div className="valuebox__items">
              <Image src={TimerImg} alt="" />
              <p>Reduce communications time</p>
            </div>
            <div className="valuebox__items">
              <Image src={ClipboardImg} alt="" />
              <p>Download all shipping documents from your account</p>
            </div>
          </div>
        </section>
        <Container>
          <Row>
            <Col md={6}>
              <div className="solution_content">
                <h4 className="solution_head">USER OPTION</h4>
                <h3>
                  Our unique platform gives you total visibility of your
                  shipments, enabling you to make informed decisions and
                  optimize your costs.
                </h3>
                <p>
                  From quotation request to tracking and delivery, you can
                  easily manage your transport from your own dedicated space
                </p>
                <p>
                  Find the best rates and trusted carriers for every delivery -
                  our platform gives you instant access to a network of quality
                  transport professionals..
                </p>

                <Button
                  as={Link}
                  to="/contact"
                  variant="primary"
                  className="btn-primaryAll btnPrimary mt-2"
                >
                  Ask for a demo <FiArrowRight />
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="solution_img solution_img1">
                <Image src={SolutionsImg2} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="shipper carries_sec">
        <Container>
          <p>
            We offer the possibility to carriers aiming to provide an excellent
            delivery service to join our outstanding network of carriers accross
            Europe
          </p>
          <Row className="justify-content-center">
            <Col md={6}>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#section4").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                    className="clientBox clientBoxWidth"
                    style={{ backgroundImage: `url(${N_Img3})` }}
                  >
                    <div>
                      <h3>JOIN OUR NETWORK OF</h3>
                      <h4>CARRIERS</h4>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="solutionsmain_div client_div" id="section4">
        <Container>
          <Row>
            <Col md={6}>
              <div className="solution_img solution_img1 mb-4">
                <Image src={SolutionsImg3} alt="" />
              </div>
            </Col>
            <Col md={6}>
              <div className="solution_content solution_contentPad">
                <h4 className="solution_head">CARRIER OPTION</h4>
                <h3>
                  The all-in-one solution for carriers "of all sizes" who want
                  to offer superior delivery service and increase their profits.
                </h3>
                <p>
                  Give your shippers unrivalled visibility of their deliveries
                  and stand out from the competition.
                </p>
                <p>
                  Join our network of carriers in Europe and take advantage of
                  new business opportunities, as our partnership guarantees
                  business growth.
                </p>

                <Button
                  as={Link}
                  to="/contact"
                  variant="primary"
                  className="btn-primaryAll btnPrimary mt-2"
                >
                  Ask for a demo <FiArrowRight />
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="py-5 align-items-center">
            <Col md={6}>
              <div className="solution_content solution_contentPad1">
                <p>
                  <FaCircleCheck className="checkFont" />
                  Give your shippers unrivalled visibility of their
                </p>
                <p>
                  <FaCircleCheck className="checkFont" />
                  Give your shippers unrivalled visibility of their
                </p>
                <p>
                  <FaCircleCheck className="checkFont" />
                  Give your shippers unrivalled visibility of their
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="solution_img12">
                <Image src={SolutionsImg4} alt="" />
              </div>
            </Col>
          </Row>
          <div className="valuebox valueboxBottom">
            <h2>OUR ADDED VALUES</h2>
            <div className="valuebox__items">
              <Image src={TimerImg} alt="" />
              <p>Save time with our quotes management system</p>
            </div>
            <div className="valuebox__items">
              <Image src={GlobalImg} alt="" />
              <p>Gain visibility in the European market</p>
            </div>
            <div className="valuebox__items">
              <Image src={BookImg} alt="" />
              <p>Keep an eye on all your deliveries from your account</p>
            </div>
            <div className="valuebox__items">
              <Image src={StatusUp} alt="" />
              <p>Improve your delivery performance</p>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Solutions;
