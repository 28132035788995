import React, { createContext, useState, useEffect } from "react";

const AppStoreContext = createContext(null);
const { Provider } = AppStoreContext;

const AppStoreDataProvider = ({ children }) => {
  const user_details = localStorage.getItem("userDetail");
  const role_access = localStorage.getItem("access");

  const [userDetail, setUserDetails] = useState(
    user_details && user_details != undefined ? JSON.parse(user_details) : {}
  );
  const [access, setAccess] = useState(
    role_access && role_access != undefined ? JSON.parse(role_access) : ""
  );

  const [isBodyClassActive, setIsBodyClassActive] = useState(false);

  useEffect(() => {
    if (isBodyClassActive) {
      document.body.classList.add("sb-sidenav-toggled");
    } else {
      document.body.classList.remove("sb-sidenav-toggled");
    }
  }, [isBodyClassActive]);

  const toggleBodyClass = () => {
    setIsBodyClassActive(!isBodyClassActive);
  };

  return (
    <Provider
      value={{
        access,
        setAccess,
        setUserDetails,
        userDetail,
        toggleBodyClass,
        isBodyClassActive,
        setIsBodyClassActive,
      }}
    >
      {children}
    </Provider>
  );
};

export { AppStoreDataProvider, AppStoreContext };
