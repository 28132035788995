import AxiosConfig from "../AxiosConfig";
import {
  USER_LOGIN,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
  USER_EDIT_DETAIL,
  USER_DETAIL,
  USER_QUOTE_LIST,
  USER_ADD_QUOTE,
  USER_SIGN_UP,
  USER_ORDER_LIST,
  USER_DELETE_ORDER,
  USER_DELETE_QUOTES,
  USER_SELECT_ORDER,
  USER_ARCHIVE_LIST,
  USER_LOADING_FILTER,
  USER_VEHICLE_FILTER,
} from "./UserApiEndpoint";

const instance = AxiosConfig.instance;

// SignUp
export const user_Signup = async (payload) => {
  try {
    const response = await instance.post(USER_SIGN_UP, payload);
    return response.data;
  } catch (error) {
    console.error("SignUp Api Error:", error);
    throw error;
  }
};

// Login
export const user_Login = async (payload) => {
  try {
    const response = await instance.post(USER_LOGIN, payload);
    return response.data;
  } catch (error) {
    console.error("Login Api Error:", error);
    throw error;
  }
};
// Forgot Password
export const user_ForgotPassword = async (payload) => {
  try {
    const response = await instance.post(USER_FORGOT_PASSWORD, payload);
    return response.data;
  } catch (error) {
    console.error("Forgot Password  Api Error:", error);
    throw error;
  }
};
// Reset Password
export const user_ResetPassword = async (payload) => {
  try {
    const response = await instance.post(USER_RESET_PASSWORD, payload);
    return response.data;
  } catch (error) {
    console.error("Reset Password  Api Error:", error);
    throw error;
  }
};
// Edit Detail
export const user_EditDetail = async (payload) => {
  try {
    const response = await instance.post(USER_EDIT_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("User Edit Detail Api Error:", error);
    throw error;
  }
};
// User Detail
export const user_Detail = async (payload) => {
  try {
    const response = await instance.post(USER_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("User Detail Api Error:", error);
    throw error;
  }
};
// User Quote List
export const user_QuoteList = async (payload) => {
  try {
    const response = await instance.post(USER_QUOTE_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("User Quote List Api Error:", error);
    throw error;
  }
};
// User Add Quote
export const user_AddQuote = async (payload) => {
  try {
    const response = await instance.post(USER_ADD_QUOTE, payload);
    return response.data;
  } catch (error) {
    console.error("User Add Quote List Api Error:", error);
    throw error;
  }
};
// User Order List
export const user_OrderList = async (payload) => {
  try {
    const response = await instance.post(USER_ORDER_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("User Order List Api Error:", error);
    throw error;
  }
};
// User Delete Order
export const user_DeleteOrder = async (payload) => {
  try {
    const response = await instance.post(USER_DELETE_ORDER, payload);
    return response.data;
  } catch (error) {
    console.error("User Delete Order Api Error:", error);
    throw error;
  }
};
// User Delete Quote
export const user_DeleteQuote = async (payload) => {
  try {
    const response = await instance.post(USER_DELETE_QUOTES, payload);
    return response.data;
  } catch (error) {
    console.error("User Delete Quote Api Error:", error);
    throw error;
  }
};
// User Filter by Select Order
export const user_SelectOrder = async (payload) => {
  try {
    const response = await instance.post(USER_SELECT_ORDER, payload);
    return response.data;
  } catch (error) {
    console.error("User select Api Error:", error);
    throw error;
  }
};
// User Archive/Delete Order
export const user_ArchiveList = async (payload) => {
  try {
    const response = await instance.post(USER_ARCHIVE_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("User select Api Error:", error);
    throw error;
  }
};
// User Loading Filter
export const user_LoadingFilter = async (payload) => {
  try {
    const response = await instance.post(USER_LOADING_FILTER, payload);
    return response.data;
  } catch (error) {
    console.error("User Loading Filter Api Error:", error);
    throw error;
  }
};
// User Vehicle Filter
export const user_VehicleFilter = async (payload) => {
  try {
    const response = await instance.post(USER_VEHICLE_FILTER, payload);
    return response.data;
  } catch (error) {
    console.error("User Vehicle Filter Api Error:", error);
    throw error;
  }
};
