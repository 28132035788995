import { useState, useEffect, useContext } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  Modal,
  Container,
  Badge,
  Dropdown,
  ButtonGroup,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";
import UserInfoIcon from "../images/userinfo.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import FilterSidebar from "../inc/FilterSidebar";
import FilterIcon from "../images/filter.svg";
import RowsImg from "../images/cchart.svg";
import { GoDotFill } from "react-icons/go";
import { FaLocationDot } from "react-icons/fa6";
import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import BlockIcon from "../images/blockicon.svg";
import SendEmailIcon from "../images/sendemail.svg";
import { FiMoreVertical } from "react-icons/fi";
import {
  addCarrier,
  blockCarrier,
  country_PhoneNumberCode,
  deleteCarrier,
  edit_Carrier,
  getCarrierList,
  particular_Carrier,
  sendEmail,
  staff_GiveCarrierList,
} from "../services/ApiCalls";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import EditFieldTextInput from "../customcomponents/EditFieldTextInput";
import LoadingContext from "../services/LoadingContext";
import { URL_LINK } from "../services/env";
import { FiMenu } from "react-icons/fi";
import SearchIcon from "../images/searchicon.svg";
import NotificationIcon from "../images/notification.svg";
import ReactPaginate from "react-js-pagination";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { AppStoreContext } from "../../store/AppStore";

var selectedItem = {};
var rateItem = {};
var deliveredItem = {};

const Carriers = () => {
  const [modalShow, setModalShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const { userDetail } = useContext(AppStoreContext);
  const { setIsLoading } = useContext(LoadingContext);

  const userDeatildata = userDetail;
  const { _id, email, profile } = userDeatildata;
  const isRoleAdmin = profile == "Admin";

  const [carrierList, setCarrierList] = useState([]);
  const [showItem, setShowItem] = useState(false);
  const [editDetail, setEditDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const [isBodyClassActive, setIsBodyClassActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedCarrierList = carrierList.slice(startIndex, endIndex);

  const calculateCount = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };

  const handlePageChange = (page) => {
    setShowItem(false);
    setCurrentPage(page);
  };

  const handleSearch = () => {
    const filteredResults = carrierList.filter(
      (result) =>
        result.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.accountNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setCarrierList(filteredResults);
    setShowItem(false);
  };

  useEffect(() => {
    if (isRoleAdmin) {
      if (searchQuery === "") {
        fetchCarrierList();
      }
    } else {
      if (searchQuery === "") {
        fetchStaffCarrierList();
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    if (isBodyClassActive) {
      document.body.classList.add("sb-sidenav-toggled");
    } else {
      document.body.classList.remove("sb-sidenav-toggled");
    }
  }, [isBodyClassActive]);

  const toggleBodyClass = () => {
    setIsBodyClassActive(!isBodyClassActive);
  };

  let count = 0;

  const handleItemShow = (item) => {
    const payLoad = {
      particularCarrier: item._id,
    };
    setIsLoading(true);
    particular_Carrier(payLoad)
      .then((res) => {
        if (res.status === true) {
          selectedItem = res.result.carrierdetail;
          rateItem = res.result.avgRating;
          deliveredItem = res.result.deliveredCount;
          setEditDetail(res.result.carrierdetail);
          setShowItem(true);
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (isRoleAdmin) {
      fetchCarrierList();
    } else {
      fetchStaffCarrierList();
    }
  }, [editModal, modalShow]);

  // Admint List
  const fetchCarrierList = () => {
    const payload = {
      adminId: _id,
    };
    setIsLoading(true);
    getCarrierList(payload)
      .then((res) => {
        setCarrierList(res.result.CarrierList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // Staff List
  const fetchStaffCarrierList = () => {
    const payload = {};
    setIsLoading(true);
    staff_GiveCarrierList(payload)
      .then((res) => {
        setCarrierList(res.result.CarrierList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  // send Email
  const handleSendEmailClick = (ownerName, staffEmail, id) => {
    const emailAddress = staffEmail;
    const subject = "";
    const body = "";
    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  // send mail API

  // const handleSendEmailClick = (ownerName, carrierEmail, id) => {
  //   const payload = {
  //     id: id,
  //     contactEmail: carrierEmail,
  //     FirstName: ownerName,
  //     link: `${URL_LINK}example`,
  //   };
  //   console.log("send mail Payload", payload);
  //   setIsLoading(true);
  //   sendEmail(payload)
  //     .then((res) => {
  //       console.log("send mail res", res);
  //       if (res.status === true) {
  //         toast("Email sent successfully.");
  //       } else toast(res.msg);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       setIsLoading(false);
  //     });
  // };

  // Edit carrier
  const handleEditClick = (item) => {
    setEditDetail(item);
    setEditModal(true);
  };

  // Block API
  const handleBlockClick = (id, check) => {
    const payload = {
      type: check === "Active" ? "Inactive" : "Active",
      carrierid: id,
    };
    setIsLoading(true);
    blockCarrier(payload)
      .then((res) => {
        if (res.status === true) {
          check === "Active"
            ? toast("Block Successfully")
            : toast("Unblock Successfully");
          setShowItem(false);
          fetchCarrierList();
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  // Delete API
  const handleDeleteClick = (id) => {
    if (window.confirm("Are you sure you want to delete this carrier?")) {
      const payload = {
        deleteStatus: "1",
        carrierid: id,
      };
      setIsLoading(true);
      deleteCarrier(payload)
        .then((res) => {
          if (res.status === true) {
            toast("Delete Successfully");
            setShowItem(false);
            fetchCarrierList();
          } else toast(res.msg);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    } else {
      console.log("cancel carrier");
    }
  };

  // const concatenatedValue =
  //   selectedItem && selectedItem?.vichleType
  //     ? selectedItem.vichleType.join(", ")
  //     : "";
  const concatenatedValue = selectedItem && selectedItem?.vichleType
    ? selectedItem.vichleType.join(", ").replace("GeneralCargo", "General cargo")
    : "";

  // const AccountNumber = ( accountNumber ) => {
  //   const firstThreeDigits = accountNumber.slice(0, 3);
  //   const restOfTheDigits = accountNumber.slice(3, 8);

  //   return (
  //     <div>
  //       <span style={{ maxWidth: 170, color: firstThreeDigits === firstThreeDigits ? 'red' : 'green' }}>
  //         {firstThreeDigits}
  //       </span>
  //       <span>{restOfTheDigits}</span>
  //     </div>
  //   );
  // };

  return (
    <>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <div className="topheader">
            <div className="container-fluid">
              <div className="topheader_flex">
                <Button
                  variant="primary"
                  className="menuBtn"
                  onClick={toggleBodyClass}
                >
                  <FiMenu />
                </Button>
                <div className="inPutBox">
                  <Form.Control
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(text) => setSearchQuery(text.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                  <Image src={SearchIcon} alt="" />
                </div>
                <Link className="notiIcon">
                  <Image src={NotificationIcon} alt="" />
                  <Badge bg="danger">5</Badge>
                </Link>
              </div>
            </div>
          </div>

          <FilterSidebar close={handleToggle} />

          <Container fluid className="pt-100">
            <div className="quotes_div">
              <Row className="align-items-center mb-3">
                {isRoleAdmin && (
                  <Col lg={6} xs={6}>
                    <Button
                      onClick={() => setModalShow(true)}
                      variant="primary"
                      className="btn-primaryAll btnPrimary"
                    >
                      <FiPlusCircle /> Add carrier
                    </Button>
                  </Col>
                )}
              </Row>

              <Row>
                <Col lg={5}>
                  <div className="react_table react_table1 react_tableLast">
                    <Table cellSpacing={10}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>NAME</th>
                          <th>ACCOUNT</th>
                          <th></th>
                        </tr>
                      </thead>

                      {carrierList.length === 0 && (
                        <tr>
                          <td colSpan={9} className="text-center  pt-3 ">
                            No data found
                          </td>
                        </tr>
                      )}

                      <tbody>
                        {displayedCarrierList.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => handleItemShow(item)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.deleteStatus === "0" && (
                                <>
                                  {" "}
                                  <td style={{ maxWidth: 170 }}>
                                    <span className="padd">
                                      {calculateCount(index)}
                                    </span>
                                  </td>
                                  <td style={{ maxWidth: 170, width: 170 }}>
                                    <span
                                      style={{
                                        maxWidth: 170,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className="bgSeconday bgSecondayr"
                                    >
                                      {item.companyName}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      style={{ maxWidth: 170 }}
                                      className="bgSeconday bgSecondayl"
                                    >
                                      {item.accountNumber}
                                    </span>
                                  </td>
                                  <td>
                                    {isRoleAdmin ? (
                                      <div className="mb-2">
                                        {["start"].map((direction) => (
                                          <DropdownButton
                                            as={ButtonGroup}
                                            key={direction}
                                            id={`dropdown-button-drop-${direction}`}
                                            drop={direction}
                                            className="tabledrop_btn"
                                            title={<FiMoreVertical />}
                                          >
                                            <Dropdown.Item
                                              eventKey="1"
                                              className="menudrop_link"
                                              onClick={() =>
                                                handleSendEmailClick(
                                                  item.accountOwnerFirstName,
                                                  item.contactEmail,
                                                  item._id
                                                )
                                              }
                                            >
                                              <Image
                                                className="editimg"
                                                src={SendEmailIcon}
                                                alt=""
                                              />{" "}
                                              Send email
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              eventKey="2"
                                              className="menudrop_link"
                                              onClick={() =>
                                                handleEditClick(item)
                                              }
                                            >
                                              <Image
                                                className="editimg"
                                                src={EditIcon}
                                                alt=""
                                              />{" "}
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              eventKey="3"
                                              className="menucancel_link"
                                              onClick={() =>
                                                handleBlockClick(
                                                  item._id,
                                                  item.status
                                                )
                                              }
                                            >
                                              <Image
                                                className="editimgt"
                                                src={BlockIcon}
                                                alt=""
                                              />{" "}
                                              {item.status === "Active"
                                                ? "Block"
                                                : "Unblock"}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              eventKey="4"
                                              className="menucancel_link"
                                              onClick={() =>
                                                handleDeleteClick(item._id)
                                              }
                                            >
                                              <Image
                                                className="editimgt"
                                                src={TrashIcon}
                                                alt=""
                                              />{" "}
                                              Delete
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        ))}
                                      </div>
                                    ) : (
                                      <div className="mb-2">
                                        {["start"].map((direction) => (
                                          <DropdownButton
                                            as={ButtonGroup}
                                            key={direction}
                                            id={`dropdown-button-drop-${direction}`}
                                            drop={direction}
                                            className="tabledrop_btn"
                                            title={<FiMoreVertical />}
                                          >
                                            <Dropdown.Item
                                              eventKey="1"
                                              className="menudrop_link"
                                              onClick={() =>
                                                handleSendEmailClick(
                                                  item.accountOwnerFirstName,
                                                  item.contactEmail,
                                                  item._id
                                                )
                                              }
                                            >
                                              <Image
                                                className="editimg"
                                                src={SendEmailIcon}
                                                alt=""
                                              />{" "}
                                              Send email
                                            </Dropdown.Item>
                                          </DropdownButton>
                                        ))}
                                      </div>
                                    )}
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>

                {showItem === true && (
                  <Col lg={7}>
                    <div className="react_table carrier_Details">
                      <div className="common_bg bg_Color">
                        <div className="headFlex">
                          <h2>{selectedItem?.companyName}</h2>
                          {isRoleAdmin && (
                            <Link onClick={() => handleEditClick(selectedItem)}>
                              <Image
                                className="editimg"
                                src={EditIcon}
                                alt=""
                              />{" "}
                              Modify
                            </Link>
                          )}
                        </div>
                        <div className="wfix">
                          {selectedItem?.status === "Inactive" ? (
                            <Badge bg="danger" className="badgeC">
                              <GoDotFill size={15} /> Inactive
                            </Badge>
                          ) : (
                            <Badge bg="success" className="badgeC">
                              <GoDotFill size={15} /> Active
                            </Badge>
                          )}
                        </div>
                      </div>
                      <div className="common_bg common_bg_pad">
                        <h3>Rate</h3>
                        <div className="wfix">
                          <h3>
                            {(!!rateItem) ? rateItem : 0}
                            /5
                          </h3>
                        </div>
                      </div>
                      <div className="common_bg bg_Color">
                        <div className="headFlex headFlex_h2">
                          <h2>Number of missions</h2>
                        </div>
                        <div className="wfix">
                          <h3>{deliveredItem}</h3>
                        </div>
                      </div>
                      <div className="common_bg common_bg_pad">
                        <h3>Account number</h3>
                        <div className="wfix">
                          <h3>{selectedItem?.accountNumber}</h3>
                        </div>
                      </div>
                      <div className="common_bg bg_Color">
                        <div className="headFlex headFlex_h2">
                          <h2>Address</h2>
                        </div>
                        <div className="wfix">
                          <h3>{selectedItem?.address}</h3>
                        </div>
                      </div>
                      <div className="common_bg common_bg_pad">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <h3>Phone</h3>
                            <div className="wfix">
                              <h3>
                                {selectedItem?.contactCode}{" "}
                                {selectedItem?.contactPhone}
                              </h3>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h3>Email</h3>
                            <div className="wfix">
                              <h3>{selectedItem?.contactEmail}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isRoleAdmin && (
                        <div className="common_bg bg_Color">
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <div className="headFlex headFlex_h2">
                                <h2>Account owner name</h2>
                              </div>
                              <div className="wfix">
                                <h3>
                                  {selectedItem?.accountOwnerFirstName}{" "}
                                  {selectedItem?.accountOwnerLastName}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="headFlex headFlex_h2">
                                <h2>Account owner phone</h2>
                              </div>
                              <div className="wfix">
                                <h3>
                                  {selectedItem?.accountOwnerCode}{" "}
                                  {selectedItem?.accountOwnerPhoneNumber}
                                </h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="headFlex headFlex_h2">
                                <h2>Account owner email</h2>
                              </div>
                              <div className="wfix">
                                <h3>{selectedItem?.accountOwnerEmail}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {isRoleAdmin && (
                        <div className="common_bg common_bg_pad">
                          <h3>VAT number</h3>
                          <div className="wfix">
                            <h3>{selectedItem?.VATNumber}</h3>
                          </div>
                        </div>
                      )}

                      {isRoleAdmin && (
                        <div className="common_bg bg_Color">
                          <div className="headFlex headFlex_h2">
                            <h2>DUNS number</h2>
                          </div>

                          <div className="wfix">
                            <h3>{selectedItem?.DUNS}</h3>
                          </div>
                        </div>
                      )}

                      {isRoleAdmin && (
                        <div className="common_bg common_bg_pad">
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <h3>IBAN</h3>
                              <div className="wfix">
                                <h3>{selectedItem?.IBAN}</h3>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h3>BIC</h3>
                              <div className="wfix">
                                <h3>{selectedItem?.BIC}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="common_bg bg_Color">
                        <div className="headFlex headFlex_h2">
                          <h2>Vehicles types</h2>
                        </div>
                        <div className="wfix">
                          <h3>{concatenatedValue}</h3>
                        </div>
                      </div>
                      <div className="common_bg common_bg_pad">
                        <h3>Offices</h3>
                        <div className="wfix">
                          <h3>{selectedItem?.MainOfficeLocation}</h3>
                        </div>
                      </div>
                      <div className="common_bg bg_Color align-items-start">
                        <div className="headFlex headFlex_h2">
                          <h2>Countries delivered</h2>
                        </div>
                        <div className="wfix">
                          <h3>{selectedItem?.countriesDelivered}</h3>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>

            {/* Pagination controls */}
            <div className="pagination-container">
              <div className="paginationFeature">
                <p>Rows per page: 10</p>
              </div>
              <div className="pagination">
                <p>
                  {currentPage} - {displayedCarrierList.length} of{" "}
                  {carrierList.length}
                </p>
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={carrierList.length}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination"
                />
              </div>
            </div>
          </Container>
          <ToastContainer />
        </div>
      </div>
      <CarrierModal
        show={modalShow}
        onShowOtherModal={() => {
          setModalShow(false);
        }}
        onHide={() => {
          setModalShow(false);
        }}
      />
      <EditCarrierModal
        show={editModal}
        loader={loading}
        setLoading={setLoading}
        itemdetail={editDetail}
        onShowOtherModal={() => {
          setEditModal(false);
        }}
        onHide={() => {
          setEditModal(false);
        }}
      />
    </>
  );
};

// Add Modal
function CarrierModal(props) {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [accountOwnerFirstName, setAccountOwnerFirstName] = useState("");
  const [accountOwnerLastName, setAccountOwnerLastName] = useState("");
  const [accountOwnerPhone, setAccountOwnerPhone] = useState("");
  const [accountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [duns, setDuns] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const [mainOfficeLocation, setMainOfficeLocation] = useState("");
  const [countryDelivered, setCountryDelivered] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const { _id } = userDeatildata;
  const { setIsLoading } = useContext(LoadingContext);
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);
  const [accountOwnerFirstNameError, setAccountOwnerFirstNameError] =
    useState(0);
  const [accountOwnerLastNameError, setAccountOwnerLastNameError] = useState(0);
  const [accountOwnerPhoneError, setAccountOwnerPhoneError] = useState(0);
  const [accountOwnerEmailError, setAccountOwnerEmailError] = useState(0);
  const [vatNumberError, setVatNumberError] = useState(0);
  const [dunsError, setDunsError] = useState(0);
  const [ibanError, setIbanError] = useState(0);
  const [bicError, setBicError] = useState(0);
  const [mainOfficeLocationError, setMainOfficeLocationError] = useState(0);
  const [countriesDeliveredError, setCountryDeliveredError] = useState(0);
  const [selectedValuesError, setSelectedValuesError] = useState(0);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedValues((prevValues) => [...prevValues, name]);
    } else {
      setSelectedValues((prevValues) =>
        prevValues.filter((value) => value !== name)
      );
    }
  };

  const checkStates = () => {
    setCompanyName("");
    setAddress("");
    setContactPhone("");
    setContactEmail("");
    setAccountOwnerFirstName("");
    setAccountOwnerLastName("");
    setAccountOwnerPhone("");
    setAccountOwnerEmail("");
    setVatNumber("");
    setDuns("");
    setIban("");
    setBic("");
    setMainOfficeLocation("");
    setCountryDelivered("");
    setSelectedValues([]);
  };

  useEffect(() => {
    onProfileValidation();
  }, [
    companyName,
    address,
    contactPhone,
    contactEmail,
    accountOwnerFirstName,
    accountOwnerLastName,
    accountOwnerPhone,
    accountOwnerEmail,
    vatNumber,
    duns,
    iban,
    bic,
    mainOfficeLocation,
    countryDelivered,
  ]);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!companyName) {
      errorCount++;
      let msg = "Please enter company name.";
      setCompanyNameError(msg);
    } else {
      setCompanyNameError(null);
    }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }

    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!accountOwnerFirstName) {
      errorCount++;
      let msg = "Please enter first name.";
      setAccountOwnerFirstNameError(msg);
    } else {
      setAccountOwnerFirstNameError(null);
    }

    if (!accountOwnerLastName) {
      errorCount++;
      let msg = "Please enter last name.";
      setAccountOwnerLastNameError(msg);
    } else {
      setAccountOwnerLastNameError(null);
    }

    if (!accountOwnerPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setAccountOwnerPhoneError(msg);
    } else {
      setAccountOwnerPhoneError(null);
    }

    let accountOwnerEmailError = SERVICE.validateEmail(accountOwnerEmail);
    if (accountOwnerEmailError !== undefined) {
      errorCount++;
      let msg =
        accountOwnerEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setAccountOwnerEmailError(msg);
    } else {
      setAccountOwnerEmailError(null);
    }

    if (!vatNumber) {
      errorCount++;
      let msg = "Please enter VAT number.";
      setVatNumberError(msg);
    } else {
      setVatNumberError(null);
    }

    if (!duns) {
      errorCount++;
      let msg = "Please enter DUNS.";
      setDunsError(msg);
    } else {
      setDunsError(null);
    }

    if (!iban) {
      errorCount++;
      let msg = "Please enter IBAN number.";
      setIbanError(msg);
    } else {
      setIbanError(null);
    }

    if (!bic) {
      errorCount++;
      let msg = "Please enter BIC number.";
      setBicError(msg);
    } else {
      setBicError(null);
    }

    if (selectedValues.length === 0) {
      errorCount++;
      let msg = "Please select vehicle type.";
      setSelectedValuesError(msg);
    } else {
      setSelectedValuesError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = (item) => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      return;
    } else {
      fetchCarrierDetial(item);
    }
  };

  const handleCancel = (item) => {
    setSubmit(false);
    item.onHide();
  };

  const fetchCarrierDetial = (item) => {
    const vichleTypeString = selectedValues.join(", ");
    const payload = {
      companyName: companyName,
      address: address,
      contactPhone: contactPhone,
      contactCode: selectedCountryCode,
      contactEmail: contactEmail,
      accountOwnerFirstName: accountOwnerFirstName,
      accountOwnerLastName: accountOwnerLastName,
      accountOwnerCode: unLoadingCountryCode,
      accountOwnerPhoneNumber: accountOwnerPhone,
      accountOwnerEmail: accountOwnerEmail,
      VATNumber: vatNumber,
      DUNS: duns,
      IBAN: iban,
      BIC: bic,
      vichleType: vichleTypeString,
      MainOfficeLocation: mainOfficeLocation,
      countriesDelivered: countryDelivered,
      adminId: _id,
      link: `${URL_LINK}user_login`,
    };

    setIsLoading(true);
    addCarrier(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Carrier has been successfully added");
          setTimeout(() => {
            item.onHide(false);
            checkStates();
            setSubmit(false);
          }, 1500);
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB76GgPj16-OjzfZsRi8TWtBYRZ6eWwQWI&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Phone code
  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Add carrier</h2>
        <BreadCrumbHeader image={UserInfoIcon} title="CARRIER INFORMATIONS" />

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Company name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={companyName}
              onChange={(text) => {
                setCompanyName(text.target.value);
              }}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Address"
              placeholder="Enter an Address"
              type="text"
              reqstart="*"
              onChange={(text) => setAddress(text.target.value)}
            />
            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={contactPhone}
                onChange={(text) => {
                  setContactPhone(text.target.value);
                }}
              />
            </div>
            {contactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Contact Email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              onChange={(text) => setContactEmail(text.target.value)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Account owner first name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              onChange={(text) => setAccountOwnerFirstName(text.target.value)}
            />
            {accountOwnerFirstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerFirstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Account owner last name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              onChange={(text) => setAccountOwnerLastName(text.target.value)}
            />
            {accountOwnerLastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerLastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Account owner phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={accountOwnerPhone}
                onChange={(text) => {
                  setAccountOwnerPhone(text.target.value);
                }}
              />
            </div>

            {accountOwnerPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Account owner email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              onChange={(text) => setAccountOwnerEmail(text.target.value)}
            />
            {accountOwnerEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="VAT number"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={vatNumber}
              onChange={(text) => {
                if (text.target.value.length <= 13) {
                  setVatNumber(text.target.value);
                }
              }}
            />
            {vatNumberError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {vatNumberError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="DUNS"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={duns}
              onChange={(text) => {
                if (text.target.value.length <= 15) {
                  setDuns(text.target.value);
                }
              }}
            />
            {dunsError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {dunsError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="IBAN"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={iban}
              onChange={(text) => {
                if (text.target.value.length <= 25) {
                  setIban(text.target.value);
                }
              }}
            />
            {ibanError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {ibanError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="BIC"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={bic}
              onChange={(text) => {
                if (text.target.value.length <= 20) {
                  setBic(text.target.value);
                }
              }}
            />
            {bicError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {bicError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="custom_radio">
          <h5>
            Vehicle(s) type <span className="requiresLable">*</span>
          </h5>
          <label>
            <input
              type="checkbox"
              name="GeneralCargo"
              className="card-input-element"
              onChange={handleCheckboxChange}
            />
            <div className="card-input">
              <div className="panel-heading">General cargo</div>
            </div>
          </label>

          <label>
            <input
              type="checkbox"
              name="Tanker"
              className="card-input-element"
              onChange={handleCheckboxChange}
            />
            <div className="card-input">
              <div className="panel-heading">Tanker</div>
            </div>
          </label>

          <label>
            <input
              type="checkbox"
              name="Refrigerated"
              className="card-input-element"
              onChange={handleCheckboxChange}
            />
            <div className="card-input">
              <div className="panel-heading">Refrigerated</div>
            </div>
          </label>
        </div>
        {selectedValuesError && Submit ? (
          <div
            style={{
              fontFamily: "AvenirLTProLight",
              color: "#FF0000",
              opacity: 0.6,
              marginTop: -13,
            }}
          >
            {selectedValuesError}
          </div>
        ) : null}
        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Main office location"
              placeholder="Enter a city"
              type="text"
              onChange={(text) => setMainOfficeLocation(text.target.value)}
            />
            {mainOfficeLocationError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {mainOfficeLocationError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Countries delivered"
              placeholder="Enter a country"
              type="text"
              onChange={(text) => setCountryDelivered(text.target.value)}
            />
            {countriesDeliveredError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {countriesDeliveredError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={() => handleCancel(props)}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            // onClick={props.onHide}
            onClick={() => onSubmit(props)}
          >
            Create this partner
          </Button>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}

// Edit Modal

function EditCarrierModal(props) {
  useEffect(() => {
    if (props.itemdetail) {
      setCompanyName(props.itemdetail.companyName);
      setAddress(props.itemdetail.address);
      setContactPhone(props.itemdetail.contactPhone);
      setContactEmail(props.itemdetail.contactEmail);
      setAccountOwnerFirstName(props.itemdetail.accountOwnerFirstName);
      setAccountOwnerLastName(props.itemdetail.accountOwnerLastName);
      setAccountOwnerPhone(props.itemdetail.accountOwnerPhoneNumber);
      setAccountOwnerEmail(props.itemdetail.accountOwnerEmail);
      setVatNumber(props.itemdetail.VATNumber);
      setDuns(props.itemdetail.DUNS);
      setIban(props.itemdetail.IBAN);
      setBic(props.itemdetail.BIC);
      setMainOfficeLocation(props.itemdetail.MainOfficeLocation);
      setCountryDelivered(props.itemdetail.countriesDelivered);
      setSelectedValues(props.itemdetail.vichleType);
      setSelectedCountryCode(props.itemdetail.contactCode);
      setUnLoadingCountryCode(props.itemdetail.accountOwnerCode);
    }
  }, [props.itemdetail]);

  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [accountOwnerFirstName, setAccountOwnerFirstName] = useState("");
  const [accountOwnerLastName, setAccountOwnerLastName] = useState("");
  const [accountOwnerPhone, setAccountOwnerPhone] = useState("");
  const [accountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [duns, setDuns] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const [mainOfficeLocation, setMainOfficeLocation] = useState("");
  const [countryDelivered, setCountryDelivered] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const { userDetail } = useContext(AppStoreContext);
  const userDeatildata = userDetail;
  const { _id } = userDeatildata;

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);
  const [accountOwnerFirstNameError, setAccountOwnerFirstNameError] =
    useState(0);
  const [accountOwnerLastNameError, setAccountOwnerLastNameError] = useState(0);
  const [accountOwnerPhoneError, setAccountOwnerPhoneError] = useState(0);
  const [accountOwnerEmailError, setAccountOwnerEmailError] = useState(0);
  const [vatNumberError, setVatNumberError] = useState(0);
  const [dunsError, setDunsError] = useState(0);
  const [ibanError, setIbanError] = useState(0);
  const [bicError, setBicError] = useState(0);
  const [mainOfficeLocationError, setMainOfficeLocationError] = useState(0);
  const [countriesDeliveredError, setCountryDeliveredError] = useState(0);
  const [selectedValuesError, setSelectedValuesError] = useState(0);

  const { setIsLoading } = useContext(LoadingContext);
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    const isFound = selectedValues.some((item) => item == name);
    if (isFound) {
      const index = selectedValues.indexOf(name);
      const oldValues = selectedValues;
      oldValues.splice(index, 1);
      setSelectedValues([...oldValues]);
    } else {
      setSelectedValues((prevValues) => [...prevValues, name]);
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [
    companyName,
    address,
    contactPhone,
    contactEmail,
    accountOwnerFirstName,
    accountOwnerLastName,
    accountOwnerPhone,
    accountOwnerEmail,
    vatNumber,
    duns,
    iban,
    bic,
    mainOfficeLocation,
    countryDelivered,
  ]);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!companyName) {
      errorCount++;
      let msg = "Please enter company name.";
      setCompanyNameError(msg);
    } else {
      setCompanyNameError(null);
    }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }

    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!accountOwnerFirstName) {
      errorCount++;
      let msg = "Please enter first name.";
      setAccountOwnerFirstNameError(msg);
    } else {
      setAccountOwnerFirstNameError(null);
    }

    if (!accountOwnerLastName) {
      errorCount++;
      let msg = "Please enter last name.";
      setAccountOwnerLastNameError(msg);
    } else {
      setAccountOwnerLastNameError(null);
    }

    if (!accountOwnerPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setAccountOwnerPhoneError(msg);
    } else {
      setAccountOwnerPhoneError(null);
    }

    let accountOwnerEmailError = SERVICE.validateEmail(accountOwnerEmail);
    if (accountOwnerEmailError !== undefined) {
      errorCount++;
      let msg =
        accountOwnerEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setAccountOwnerEmailError(msg);
    } else {
      setAccountOwnerEmailError(null);
    }

    if (!vatNumber) {
      errorCount++;
      let msg = "Please enter VAT number.";
      setVatNumberError(msg);
    } else {
      setVatNumberError(null);
    }

    if (!duns) {
      errorCount++;
      let msg = "Please enter DUNS.";
      setDunsError(msg);
    } else {
      setDunsError(null);
    }

    if (!iban) {
      errorCount++;
      let msg = "Please enter IBAN number.";
      setIbanError(msg);
    } else {
      setIbanError(null);
    }

    if (!bic) {
      errorCount++;
      let msg = "Please enter BIC number.";
      setBicError(msg);
    } else {
      setBicError(null);
    }

    if (!selectedValues) {
      errorCount++;
      let msg = "Please select vehicle type.";
      setSelectedValuesError(msg);
    } else {
      setSelectedValuesError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = (item) => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      return;
    } else {
      editCarrierDetail(item);
    }
  };

  const editCarrierDetail = (item) => {
    const vichleTypeString = selectedValues.join(", ");
    const payload = {
      companyName: companyName,
      address: address,
      contactPhone: contactPhone,
      contactEmail: contactEmail,
      contactCode: selectedCountryCode,
      accountOwnerFirstName: accountOwnerFirstName,
      accountOwnerLastName: accountOwnerLastName,
      accountOwnerCode: unLoadingCountryCode,
      accountOwnerPhoneNumber: accountOwnerPhone,
      accountOwnerEmail: accountOwnerEmail,
      VATNumber: vatNumber,
      DUNS: duns,
      IBAN: iban,
      BIC: bic,
      vichleType: vichleTypeString,
      MainOfficeLocation: mainOfficeLocation,
      countriesDelivered: countryDelivered,
      adminId: _id,
      carrierId: props.itemdetail._id,
    };

    setIsLoading(true);
    edit_Carrier(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Carrier details have been updated successfully");
          selectedItem = res.result.data;
          props.setLoading(true);
          setTimeout(() => {
            item.onHide(false);
          }, 1500);
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  //phone code
  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>Edit carrier</h2>
        <BreadCrumbHeader image={UserInfoIcon} title="CARRIER INFORMATIONS" />

        <Row>
          <Col md={6}>
            <EditFieldTextInput
              label="Company name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={companyName}
              onChange={(text) => setCompanyName(text)}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Address"
              placeholder="Enter an Address"
              type="text"
              reqstart="*"
              value={address}
              onChange={(text) => setAddress(text)}
            />

            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <EditFieldTextInput
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={contactPhone}
                onChange={(text) => {
                  setContactPhone(text);
                }}
              />
            </div>
            {contactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Contact Email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              value={contactEmail}
              onChange={(text) => setContactEmail(text)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Account owner first name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={accountOwnerFirstName}
              onChange={(text) => setAccountOwnerFirstName(text)}
            />
            {accountOwnerFirstNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerFirstNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Account owner last name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={accountOwnerLastName}
              onChange={(text) => setAccountOwnerLastName(text)}
            />
            {accountOwnerLastNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerLastNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <EditFieldTextInput
                label="Account owner phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={accountOwnerPhone}
                onChange={(text) => {
                  setAccountOwnerPhone(text);
                }}
              />
            </div>

            {accountOwnerPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Account owner email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              value={accountOwnerEmail}
              onChange={(text) => setAccountOwnerEmail(text)}
            />
            {accountOwnerEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {accountOwnerEmailError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="VAT number"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={vatNumber}
              onChange={(text) => {
                setVatNumber(text);
              }}
            />
            {vatNumberError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {vatNumberError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="DUNS"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={duns}
              onChange={(text) => {
                setDuns(text);
              }}
            />
            {dunsError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {dunsError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="IBAN"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={iban}
              onChange={(text) => {
                setIban(text);
              }}
            />
            {ibanError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {ibanError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="BIC"
              placeholder="Enter a number"
              type="text"
              reqstart="*"
              value={bic}
              onChange={(text) => {
                setBic(text);
              }}
            />
            {bicError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {bicError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="custom_radio">
          <h5>
            Vehicle(s) type <span className="requiresLable">*</span>
          </h5>
          <label>
            <input
              type="checkbox"
              name="GeneralCargo"
              className="card-input-element"
              onChange={handleCheckboxChange}
            />
            <div
              className={`card-input ${
                selectedValues &&
                selectedValues.some((item) => item == "GeneralCargo")
                  ? `tanker_selection_opt_bg_color `
                  : "tanker_selection_opt_bg_color_alternate"
              }`}
            >
              <div className="panel-heading">General cargo</div>
            </div>
          </label>

          <label>
            <input
              type="checkbox"
              name="Tanker"
              className="card-input-element"
              onChange={handleCheckboxChange}
            />
            <div
              className={`card-input ${
                selectedValues &&
                selectedValues.some((item) => item == "Tanker")
                  ? `tanker_selection_opt_bg_color `
                  : "tanker_selection_opt_bg_color_alternate"
              }`}
            >
              <div className="panel-heading">Tanker</div>
            </div>
          </label>

          <label>
            <input
              type="checkbox"
              name="Refrigerated"
              className="card-input-element"
              onChange={handleCheckboxChange}
            />
            <div
              className={`card-input ${
                selectedValues &&
                selectedValues.some((item) => item == "Refrigerated")
                  ? `tanker_selection_opt_bg_color `
                  : "tanker_selection_opt_bg_color_alternate"
              }`}
            >
              <div className="panel-heading">Refrigerated</div>
            </div>
          </label>
        </div>
        {selectedValuesError && Submit ? (
          <div
            style={{
              fontFamily: "AvenirLTProLight",
              color: "#FF0000",
              opacity: 0.6,
              marginTop: -13,
            }}
          >
            {selectedValuesError}
          </div>
        ) : null}
        <Row>
          <Col md={6}>
            <EditFieldTextInput
              label="Main office location"
              placeholder="Enter a city"
              type="text"
              value={mainOfficeLocation}
              onChange={(text) => setMainOfficeLocation(text)}
            />
            {mainOfficeLocationError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {mainOfficeLocationError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <EditFieldTextInput
              label="Countries delivered"
              placeholder="Enter a country"
              type="text"
              value={countryDelivered}
              onChange={(text) => setCountryDelivered(text)}
            />
            {countriesDeliveredError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {countriesDeliveredError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={props.onHide}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => {
              onSubmit(props);
            }}
          >
            Save Changes
          </Button>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}

export default Carriers;
