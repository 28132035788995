import React from "react";
import { Form } from "react-bootstrap";

const TextInputWithoutLabel = (props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Control
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
      />
    </Form.Group>
  );
};

export default TextInputWithoutLabel;
