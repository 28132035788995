import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../images/logo.svg";
import {
  BiLogoLinkedin,
  BiLogoFacebook,
  BiLogoInstagramAlt,
} from "react-icons/bi";
import const_config from "../../utility/config/const_config";

const Footer = () => {
  // const currentpath = window.location.hash.split("#")[1]
  //   ? window.location.hash.split("#")[1].toString().trim()
  //   : "";
  const currentpath = window.location.pathname;
  const Paths = const_config.show_header_footer_paths;
  const isShowHeader = () => {
    const condition = Paths.includes(currentpath);
    return condition;
  };

  return (
    <>
      {isShowHeader() && (
        <section className="footer">
          <Container>
            <Row>
              <Col lg={3} md={4} sm={4}>
                <div className="footertop_div">
                  <Image src={Logo} className="footerlogo" />
                  <ul className="footerlink">
                    <li>
                      <Link to="mailto:contact@mottruck.com">
                        contact@mottruck.com
                      </Link>
                    </li>
                    <li>
                      <Link to="tel:06 85 14 20 36">(+33) 06 85 14 20 36</Link>
                    </li>
                  </ul>
                  <ul className="footersocial">
                    <li>
                      <Link>
                        <BiLogoFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <BiLogoInstagramAlt />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <BiLogoLinkedin />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={12}>
                <div className="copyright">
                  <p>
                    Copyright © 2023 Mottruck | All Rights Reserved |{" "}
                    <Link>Terms and Conditions </Link> |{" "}
                    <Link>Privacy Policy</Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Footer;
