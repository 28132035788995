import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    x: {
      grid: {
        display: false, // Hide vertical grid lines
      },
    },
    y: {
      min: 0, // Set the minimum value for the Y-axis
      max: 5, // Set the maximum value for the Y-axis
      ticks: {
        stepSize: 1, // Ensures that only integers are displayed
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

export function LineChart() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://mottruck.com:3001/client-rating-graph"
        );
        if (response.data.status === 1) {
          const data = response.data.data;
          const labels = data.map((item) => item.month);
          const ratings = data.map((item) => item.averageRating);
          console.log("client-rating-graph", labels, ratings);

          setChartData({
            labels,
            datasets: [
              {
                label: "Client satisfaction",
                data: ratings,
                borderColor: "#EF5DA8",
                backgroundColor: "#EF5DA8",
              },
            ],
          });
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return <Line options={options} data={chartData} />;
}
