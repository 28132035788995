import "./Loader.css";

import React from "react";

const LoaderComponent = () => {
  return (
    <div className="loader">
    <div class="load"></div>

    </div>
  );
};

export default LoaderComponent;